.catalog-status {
  font-weight: 400;
  color: #7e7e7e;
}

.catalog-items {
  margin-top: 1.5em;
}

.catalog-item {
  background: #fff;
  margin-bottom: 1rem;
  padding: 0 0 1.5rem;
  border-bottom: dashed 1px #ddd;
}
.catalog-item:last-of-type {
  border: 0;
}
.catalog-item-title {
  margin-bottom: 0;
}

.catalog-item-title a {
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.single-title {
  font-size: 34px;
  font-weight: 600;
  color: #000;
}

.more-link {
  display: inline-block; 
  text-transform: uppercase;
  border: solid 1px #dfdfdf;
  font-size: 0.7rem;
  font-weight: 600;
  color: #235ad6;
  padding: 0.8rem 1rem 0.6rem;
  text-decoration: none;
  line-height: 1;
}

#catalog-filter label { 
  margin-bottom: 0;
}

.catalog-item .price {
  margin-left: 1.5em;
  font-weight: 700;
}

.rating-stars {
  font-size: 0.8em;
  color: #FA6041;
}

.brief-items-stack {
    position: relative;
    top: 2px;
    margin-left: 1em;
    color: #6c757d
}

.brief-items-stack .brief-item:not(:last-child):after {
    content: "\00b7";
    margin: 0 0.2em 0 0.4em;
}

.catalog-item-description {
  margin: 0.8em 0;
}

.catalog-items {
  margin-bottom: 1.5em
}

.pagination {
  justify-content: center;
  margin-top: 3em;
  max-width: 100%;
  flex-flow: row wrap;
}

.catalog-filters {
  display: flex;
  flex-flow: row wrap;
}
.catalog-filter:not(:empty) {
  margin: 0.5em 0 0em;
}

.catalog-filters .alert {
margin-bottom: 0;
    margin-right: 0.5em !important;
    padding: .5em 0.8em;
    padding-right: 2.5em;
    white-space: nowrap;
    margin-bottom: 0em;
    font-size: 90%;
      border: solid 1px rgba(0,0,0,0.15);
  background: #fff;
}
.catalog-filters .alert button {
  padding: 0 10px;
  height: 34px;
}
.catalog-filters .alert button:focus {
  outline: none;
  border: none;
    color: #a81919;
}

.catalog-sidebar {
  font-size: 1em;
  color: #404952;
}
.catalog-sidebar {
    
    .filter-group {
      padding-bottom: 1.2rem;
      border-bottom: solid 1px #e8e8e8;
    }

    h4 {
    font-size: 1.1em;
    font-weight: 400;
    margin: 1.5em 0 0.6em;
    color: #0e0e0f;
    }

    > div {

      padding-bottom: 0.3em;

    }

    input[type=checkbox] {
      margin-right: 0.2em;
      position: relative;
      top: 1px;
    }

    .text-muted { 
      color: #9ba6b0 !important; 
    }

}

.payment-type {
  
  background: #FFF;
  border: solid 1px #f3f3f4;
  padding: 1rem; 
  border-radius: 8px;

}

.payment-type:hover,
.payment-type.active {
    /* border-color: rgba(56, 193, 114, 0.2); */
    box-shadow: 0 0 0px 1px rgba(56, 193, 114, 0.2);
    cursor: pointer;
    background: rgba(56, 193, 114, 0.015);
}

.payment-type {
  
  img {
    max-width: 100%;
    max-height: 35px;
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }

}
.btn-return {
  line-height: 35px;
  font-size: 14px !important;
}
.btn-checkout {
  font-size: 1.3rem;
  padding: 0.5em 1em;
  i {
    font-size: 50%;
    line-height: 100%;
    margin-left: 5px;
    position: relative;
    top: -3px;
  }
}
.cart {
  
  .brief-items-stack {
    margin-left: 0;
    top: 0;
  }

  img {
    max-width: 40px;
    border: solid 1px #dee2e6;
    width: 100%;
  }

}

h3.checkout-sidebar-heading {
    color: #212923e3;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0.7em;
}
.checkout-sidebar-block p {
  margin-bottom: 0.5em;
}
.checkout-sidebar-heading i {
    margin-right: 0.2em;
    color: #dd5639;
}
.checkout-sidebar-block {
    padding: 2em 0 0.8em;
}
.checkout-sidebar-block:not(:last-child) {
    border-bottom: none;
}
@media screen and (max-width: 991px) {
  #catalog-filter {
    .custom-control-label:before,
    .custom-control-label:after {
      left: 1em;
      top: 0.7em;
    }
    .aside-title:after {
      content: " -";
    }
    .aside-title.collapsed:after {
      content: " +";
    }
    .catalog-sidebar h4 {
    border: 0;
    margin-top: 0;
    margin-top: 10px;
    }
    .catalog-sidebar .filter-group {
      border: 0;
      padding: 0;
    }
    .filter-group-values {
      margin-bottom: 20px;
    }
    .filter-group-values label {
      border: solid 1px #ddd;
      margin-bottom: 4px;
      padding: 6px 10px;
      padding-left: 40px;
      background: #fff;
      position: relative;
      width: 100%;
      display: block;
      border-radius: 5px;
    }
    .filter-group-values > div {
      position: relative;
    }
    .filter-group-values input {
      position: absolute;
      left: 10px;
      z-index: 10000;
      top: 11px;
    }
    .filter-group-values input:checked + label {
background: #eee;
    border: solid 1px #eee;
    color: #444;
    }
    .filter-group-values div.checked {
background: #eee;
    border: solid 1px #eee;
    color: #444;
    }
    label .text-muted {
      position: absolute;
      right: 10px;
    }
    .filter-group-values input:checked + label .text-muted {
      color: rgba(0,0,0,0.4) !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .catalog-item-title a {
    font-size: 1.2rem;
    line-height: 1.2;
  }
  .catalog-item-title {
    font-size: 1rem;
    margin-bottom: 5px;
    letter-spacing: -0.5px;
    margin-top: 10px;
  }
  .catalog-item-title {
    line-height: 1;
  }
  .catalog-item-description {
    line-height: 1.3
  }
}
.mobile-search {
  background: #235ad6;
  margin-top: -55px;
  left: 0;
  width: 100%;
  padding-top: 7px;
  height: 50px;
  input, button {
    height: 40px;
  }
}
@media screen and (max-width: 991px) {
  .filters-container {
    margin-top: 50px;
  }
  .catalog-sidebar .filter-group {
    border: 0;
    padding: 0;
  }
  .catalog-filters {
    margin-top: 1em;
  }
.catalog-sidebar {

  h4 {
    border: solid 1px #ddd;
    padding: 13px 16px;
    background: #FFF;
    border-radius: 5px;
    margin-bottom: 0.3em;
    margin-top: 0;
    font-size: 13px;
    position: relative;
  }
  h4:before {
    content: "-";
    position: absolute;
    right: 20px;
    font-size: 20px;
    color: #555;
    line-height: 20px;
  }
  h4.collapsed:before {
    content: "+";
  }

}
.filters-container button {
  margin-top: 0.5em;
}
.catalog-filters .alert {
  border: solid 1px rgba(0,0,0,0.15);
  font-size: 12px;
  background: #fff;
  font-weight: 400;
  padding: 0 30px 0px 10px;
  height: 30px;
  line-height: 30px;
}
.catalog-filters {
  margin-bottom: 1.5em;
}
.catalog-filters:empty {
  margin-bottom: 0;
}
.catalog-filters .alert button {
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
}
.mobile-search form {
  flex-flow: row;
  width: 100%;
}
.mobile-search .form-control {
  border-radius: 5px 0 0 5px
}
.mobile-search button {
  border-radius: 0 5px 5px 0;
}
.more-filters {
  align: right;
  margin-left: 10px;
  line-height: 1;
  border: solid 1px;
  color: #fff;
  background: transparent;
  border-radius: 5px !important;
  font-size: 12px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  width: 70px;
}
a.more-filters:hover,
a.more-filters:focus {
  color: #fff;
}
.mobile-search .form-control {
  flex: 0;
}
.form-group.stretch {
  flex-grow: 1;
}
}
#catalog-filter .custom-control {
  padding-left: 5px;
}
#catalog-filter .search-button {
      border: solid 1px #ced4da;
    border-left: 0;
    color: #3490dc;
        border-radius: 0 5px 5px 0;
}
.flex-one-row .form-control {
  border-radius: 5px 0 0 5px;
}
@media screen and (max-width: 991px) {

  .mobile-search {
    position: fixed;
    z-index: 100;
  }
}
@media screen and (min-width: 992px) {
  .filter-group-subcategory .custom-control:nth-of-type(n+8) {
    display: none;
  }
  .catalog-sidebar {
    flex: 0 0 300px;
    max-width: 270px;
  }
  .catalog-content {
    flex: 0 0 calc(100% - 270px);
    padding-left: 30px;
    max-width: 800px;
  }
  #catalog-filter {
    width: 240px;
    z-index: 950;
  }
}
.mobile-catalog-search .form-group {
  display: block !important;
}
.mobile-catalog-search .form-inline .form-control {
  width: 100% !important;
}
@media screen and (max-width: 767px) {
    .catalog-content {
    margin-top: 15px;
  }
}
.small-preview {
  img {
    width: auto !important;
  }
}
@media screen and (min-width: 1366px) {
  .catalog-content {
    padding-left: 50px;
  }
}
.mobile-input {
  font-size: 16px;
  line-height: 37px;
  height:37px;
}
.mobile-catalog-search {
  display: flex;
  .search-form {
    flex-shrink: 0;
    width: calc(100% - 85px);
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-group.stretch {

  }
}
.single-search-box {
  background: #FAFAFA;
  border-radius: 4px;
  padding: 3rem;
  text-align: center;
  h4 {
    color: #000;
    font-weight: 600;
    font-size: 1.8em;
   
  }
  input[type=text] {
    text-align: center;
  }
}