.md-navbar {
  background: #fff;
  margin-bottom: 2.5em;
  position: fixed;
  width: 100%;
  padding: 0;
  top: 0;
  padding: .86rem 0rem;
  align-items: center;
  z-index: 1000;
  box-shadow: inset 0px -1px 0px #f3f4f4;
}

.md-navbar form {
  width: 100%;
  margin-right: 50px;
}

.md-navbar .container {
  width: 100%;
}

.md-navbar.sticky {
  border-bottom: 0;
  padding-bottom: 1rem;
}

.navbar-brand {
  margin-right: 3rem;
}

.navbar-brand img {
  height: 37px;
  position: relative;
  top: 3px;
}

.single-letter-avatar {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #dd5639;
  line-height: 40px;
  border-radius: 8px;
  margin-left: .7rem;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .md-navbar {
    padding: .6rem 0;
  }
}

@media screen and (min-width: 992px) {
  .header-search button[type=submit] {
    display: none;
  }

  .header-search input[type=text] {
    padding-left: 50px !important;
  }

  .header-search:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: 24px;
    top: 13px;
    color: #6c757d;
  }

  .header-buttons {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}

.centered-heading {
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
  margin: 2.3em 0 1em;
}

.page-title {
  font-size: 1.7rem;
  font-weight: 800;
  margin-bottom: 1em;
}

.page-title+p {
  margin-top: -1.5em;
}

.top-spacing {
  margin-top: 2em;
}

.centered-heading+p {
  margin: 0 auto 3em auto;
  max-width: 700px;
}

.small-container {
  max-width: 600px !important;
  margin: 0 auto;
}

.medium-container {
  padding: 0 5%;
}

table tr:first-child th:first-child {
  border-bottom-left-radius: 10px;
}

table tr:first-child th:last-child {
  border-bottom-right-radius: 10px;
}

.table thead th {
  color: #6c757d;
  border-bottom-width: 1px;
  font-weight: 400;
  padding: .5rem 1rem;
}

.table thead th:focus {
  outline: none;
}

th.sorting_asc:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  color: #000;
  margin-left: 5px;
  font-weight: 900;
  position: relative;
  top: 3px;
  color: #555;
}

th.sorting_asc,
th.sorting_desc {
  color: #555 !important;
}

th.sorting_desc:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  color: #000;
  margin-left: 5px;
  font-weight: 900;
  position: relative;
  top: -3px;
  color: #555;
}

th.sorting:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  color: #000;
  margin-left: 5px;
  font-weight: 900;
  color: #ccc;
}

th.sorting,
th.sorting_asc,
th.sorting_desc {
  cursor: pointer;
}

.table:not(.table-sm) tbody td {
  padding: 1.2rem 1rem;
  line-height: 1.2;
  vertical-align: middle;
}

.min-width-900 {
  min-width: 900px;
}

.page-heading {
  font-weight: 700;
}

.flex-one-row {
  display: flex;
}

.aside-title {
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.credits-table.bordered-table {
  border-color: #000;
}

.btn-outline-orange {
  color: #fff !important;
  border: solid 1px #fff;
}

.btn-outline-orange:hover {
  color: #fff !important;
}

.catalog-status {
  font-weight: 400;
  color: #7e7e7e;
}

.catalog-items {
  margin-top: 1.5em;
}

.catalog-item {
  background: #fff;
  margin-bottom: 1rem;
  padding: 0 0 1.5rem;
  border-bottom: dashed 1px #ddd;
}

.catalog-item:last-of-type {
  border: 0;
}

.catalog-item-title {
  margin-bottom: 0;
}

.catalog-item-title a {
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.single-title {
  font-size: 34px;
  font-weight: 600;
  color: #000;
}

.more-link {
  display: inline-block;
  text-transform: uppercase;
  border: solid 1px #dfdfdf;
  font-size: .7rem;
  font-weight: 600;
  color: #235ad6;
  padding: .8rem 1rem .6rem;
  text-decoration: none;
  line-height: 1;
}

#catalog-filter label {
  margin-bottom: 0;
}

.catalog-item .price {
  margin-left: 1.5em;
  font-weight: 700;
}

.rating-stars {
  font-size: .8em;
  color: #fa6041;
}

.brief-items-stack {
  position: relative;
  top: 2px;
  margin-left: 1em;
  color: #6c757d;
}

.brief-items-stack .brief-item:not(:last-child):after {
  content: "·";
  margin: 0 .2em 0 .4em;
}

.catalog-item-description {
  margin: .8em 0;
}

.catalog-items {
  margin-bottom: 1.5em;
}

.pagination {
  justify-content: center;
  margin-top: 3em;
  max-width: 100%;
  flex-flow: row wrap;
}

.catalog-filters {
  display: flex;
  flex-flow: row wrap;
}

.catalog-filter:not(:empty) {
  margin: .5em 0 0em;
}

.catalog-filters .alert {
  margin-bottom: 0;
  margin-right: .5em !important;
  padding: .5em .8em;
  padding-right: 2.5em;
  white-space: nowrap;
  margin-bottom: 0em;
  font-size: 90%;
  border: solid 1px rgba(0,0,0,.15);
  background: #fff;
}

.catalog-filters .alert button {
  padding: 0 10px;
  height: 34px;
}

.catalog-filters .alert button:focus {
  outline: none;
  border: none;
  color: #a81919;
}

.catalog-sidebar {
  font-size: 1em;
  color: #404952;
}

.catalog-sidebar .filter-group {
  padding-bottom: 1.2rem;
  border-bottom: solid 1px #e8e8e8;
}

.catalog-sidebar h4 {
  font-size: 1.1em;
  font-weight: 400;
  margin: 1.5em 0 .6em;
  color: #0e0e0f;
}

.catalog-sidebar>div {
  padding-bottom: .3em;
}

.catalog-sidebar input[type=checkbox] {
  margin-right: .2em;
  position: relative;
  top: 1px;
}

.catalog-sidebar .text-muted {
  color: #9ba6b0 !important;
}

.payment-type {
  background: #fff;
  border: solid 1px #f3f3f4;
  padding: 1rem;
  border-radius: 8px;
}

.payment-type:hover,
.payment-type.active {
  box-shadow: 0 0 0px 1px rgba(56,193,114,.2);
  cursor: pointer;
  background: rgba(56,193,114,.015);
}

.payment-type img {
  max-width: 100%;
  max-height: 35px;
  margin-bottom: .25rem;
}

.payment-type p {
  margin-bottom: 0;
  white-space: nowrap;
}

.btn-return {
  line-height: 35px;
  font-size: 14px !important;
}

.btn-checkout {
  font-size: 1.3rem;
  padding: .5em 1em;
}

.btn-checkout i {
  font-size: 50%;
  line-height: 100%;
  margin-left: 5px;
  position: relative;
  top: -3px;
}

.cart .brief-items-stack {
  margin-left: 0;
  top: 0;
}

.cart img {
  max-width: 40px;
  border: solid 1px #dee2e6;
  width: 100%;
}

h3.checkout-sidebar-heading {
  color: #212923e3;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: .7em;
}

.checkout-sidebar-block p {
  margin-bottom: .5em;
}

.checkout-sidebar-heading i {
  margin-right: .2em;
  color: #dd5639;
}

.checkout-sidebar-block {
  padding: 2em 0 .8em;
}

.checkout-sidebar-block:not(:last-child) {
  border-bottom: none;
}

@media screen and (max-width: 991px) {
  #catalog-filter .custom-control-label:before,
  #catalog-filter .custom-control-label:after {
    left: 1em;
    top: .7em;
  }

  #catalog-filter .aside-title:after {
    content: " -";
  }

  #catalog-filter .aside-title.collapsed:after {
    content: " +";
  }

  #catalog-filter .catalog-sidebar h4 {
    border: 0;
    margin-top: 0;
    margin-top: 10px;
  }

  #catalog-filter .catalog-sidebar .filter-group {
    border: 0;
    padding: 0;
  }

  #catalog-filter .filter-group-values {
    margin-bottom: 20px;
  }

  #catalog-filter .filter-group-values label {
    border: solid 1px #ddd;
    margin-bottom: 4px;
    padding: 6px 10px;
    padding-left: 40px;
    background: #fff;
    position: relative;
    width: 100%;
    display: block;
    border-radius: 5px;
  }

  #catalog-filter .filter-group-values>div {
    position: relative;
  }

  #catalog-filter .filter-group-values input {
    position: absolute;
    left: 10px;
    z-index: 10000;
    top: 11px;
  }

  #catalog-filter .filter-group-values input:checked+label {
    background: #eee;
    border: solid 1px #eee;
    color: #444;
  }

  #catalog-filter .filter-group-values div.checked {
    background: #eee;
    border: solid 1px #eee;
    color: #444;
  }

  #catalog-filter label .text-muted {
    position: absolute;
    right: 10px;
  }

  #catalog-filter .filter-group-values input:checked+label .text-muted {
    color: rgba(0,0,0,.4) !important;
  }
}

@media screen and (max-width: 600px) {
  .catalog-item-title a {
    font-size: 1.2rem;
    line-height: 1.2;
  }

  .catalog-item-title {
    font-size: 1rem;
    margin-bottom: 5px;
    letter-spacing: -0.5px;
    margin-top: 10px;
  }

  .catalog-item-title {
    line-height: 1;
  }

  .catalog-item-description {
    line-height: 1.3;
  }
}

.mobile-search {
  background: #235ad6;
  margin-top: -55px;
  left: 0;
  width: 100%;
  padding-top: 7px;
  height: 50px;
}

.mobile-search input,
.mobile-search button {
  height: 40px;
}

@media screen and (max-width: 991px) {
  .filters-container {
    margin-top: 50px;
  }

  .catalog-sidebar .filter-group {
    border: 0;
    padding: 0;
  }

  .catalog-filters {
    margin-top: 1em;
  }

  .catalog-sidebar h4 {
    border: solid 1px #ddd;
    padding: 13px 16px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: .3em;
    margin-top: 0;
    font-size: 13px;
    position: relative;
  }

  .catalog-sidebar h4:before {
    content: "-";
    position: absolute;
    right: 20px;
    font-size: 20px;
    color: #555;
    line-height: 20px;
  }

  .catalog-sidebar h4.collapsed:before {
    content: "+";
  }

  .filters-container button {
    margin-top: .5em;
  }

  .catalog-filters .alert {
    border: solid 1px rgba(0,0,0,.15);
    font-size: 12px;
    background: #fff;
    font-weight: 400;
    padding: 0 30px 0px 10px;
    height: 30px;
    line-height: 30px;
  }

  .catalog-filters {
    margin-bottom: 1.5em;
  }

  .catalog-filters:empty {
    margin-bottom: 0;
  }

  .catalog-filters .alert button {
    height: 30px;
    line-height: 30px;
    padding: 0px 10px;
  }

  .mobile-search form {
    flex-flow: row;
    width: 100%;
  }

  .mobile-search .form-control {
    border-radius: 5px 0 0 5px;
  }

  .mobile-search button {
    border-radius: 0 5px 5px 0;
  }

  .more-filters {
    align: right;
    margin-left: 10px;
    line-height: 1;
    border: solid 1px;
    color: #fff;
    background: transparent;
    border-radius: 5px !important;
    font-size: 12px;
    padding: 5px;
    display: inline-block;
    text-align: center;
    width: 70px;
  }

  a.more-filters:hover,
  a.more-filters:focus {
    color: #fff;
  }

  .mobile-search .form-control {
    flex: 0;
  }

  .form-group.stretch {
    flex-grow: 1;
  }
}

#catalog-filter .custom-control {
  padding-left: 5px;
}

#catalog-filter .search-button {
  border: solid 1px #ced4da;
  border-left: 0;
  color: #3490dc;
  border-radius: 0 5px 5px 0;
}

.flex-one-row .form-control {
  border-radius: 5px 0 0 5px;
}

@media screen and (max-width: 991px) {
  .mobile-search {
    position: fixed;
    z-index: 100;
  }
}

@media screen and (min-width: 992px) {
  .filter-group-subcategory .custom-control:nth-of-type(n+8) {
    display: none;
  }

  .catalog-sidebar {
    flex: 0 0 300px;
    max-width: 270px;
  }

  .catalog-content {
    flex: 0 0 calc(100% - 270px);
    padding-left: 30px;
    max-width: 800px;
  }

  #catalog-filter {
    width: 240px;
    z-index: 950;
  }
}

.mobile-catalog-search .form-group {
  display: block !important;
}

.mobile-catalog-search .form-inline .form-control {
  width: 100% !important;
}

@media screen and (max-width: 767px) {
  .catalog-content {
    margin-top: 15px;
  }
}

.small-preview img {
  width: auto !important;
}

@media screen and (min-width: 1366px) {
  .catalog-content {
    padding-left: 50px;
  }
}

.mobile-input {
  font-size: 16px;
  line-height: 37px;
  height: 37px;
}

.mobile-catalog-search {
  display: flex;
}

.mobile-catalog-search .search-form {
  flex-shrink: 0;
  width: calc(100% - 85px);
}

.mobile-catalog-search .form-group {
  margin-bottom: 0;
}

.single-search-box {
  background: #fafafa;
  border-radius: 4px;
  padding: 3rem;
  text-align: center;
}

.single-search-box h4 {
  color: #000;
  font-weight: 600;
  font-size: 1.8em;
}

.single-search-box input[type=text] {
  text-align: center;
}

.page-footer {
  padding: 4rem 2rem 0;
  background: #0d2657;
  margin-top: 10em;
  color: rgba(255,255,255,.9);
  background: #fff;
  margin-top: 10em;
  color: #222;
  box-shadow: 0 0 3px rgba(0,0,0,.15);
  padding-bottom: 1em;
}

.page-footer h5 {
  font-size: 1.2em;
}

.copyright {
  margin: 1em 0;
  font-size: 90%;
  opacity: .9;
}

.page-footer a {
  color: #222;
  color: #6c757d;
  line-height: 2;
  font-size: 1em;
}

hr {
  margin-top: 1em;
}

#footer-logo a {
  display: inline-block;
  width: 50px;
  overflow: hidden;
  vertical-align: middle;
}

#footer-logo img {
  float: left;
  width: 240px;
  vertical-align: middle;
}

.copyright {
  margin-top: 10px;
  font-size: .9em;
  line-height: 1.5;
  display: block;
}

.btn-upload {
  padding: 1rem;
  font--size: 1.1rem;
}

.paper-extension-icon {
  font-size: 30px;
  margin-left: 1rem;
}

.paper-count {
  background: rgba(108,117,125,.44);
  border-radius: 100%;
  color: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin-left: .5em;
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
}

.nav-tabs {
  margin: 1.5em 0;
  border-bottom: solid 1px #ccc;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 2px solid #38c172 !important;
  padding: 1.3em 0;
  color: #38c172;
}

.nav-link.active .paper-count {
  background: #38c172;
}

.nav-tabs .nav-link:not(.active):hover {
  border-color: transparent !important;
  color: #222;
}

.nav-tabs .nav-link {
  border-bottom: 2px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  padding: 1.3em 0;
  margin-right: 1.5em;
  color: #6c757d;
  font-size: .93em;
  letter-spacing: .02em;
  transition: all 300ms;
}

.btn-new-paper {
  padding: .5em;
  height: 39px;
  margin-top: 11px;
  line-height: 21px;
  font-weight: 600;
  color: #fff !important;
}

.btn-new-paper ion-icon {
  position: relative;
  top: 3px;
}

.dataTables_info {
  padding: .5rem 1.25rem;
}

.action-icons,
.action-icons .btn-link {
  font-size: 25px;
  padding: 0;
}

.btn-sm ion-icon {
  font-size: 110%;
  position: relative;
  top: 3px;
}

.action-icons a:hover {
  text-decoration: none;
}

.paper-data-form .row {
  padding-top: 1rem;
  transition: background 300ms;
  border: dashed 1px transparent;
  border-radius: 10px;
}

.paper-data-form .row:hover {
  border-color: #e0e0e0;
}

.paper-data-form .row:hover label {
  font-weight: 600;
}

.paper-data-form .row label {
  font-size: 1.15em;
}

.page-introduction {
  margin-bottom: 2em;
}

.input-explanator {
  margin-top: .7em;
  font-size: .9em;
}

.margin-top {
  margin-top: 1em;
}

.page-introduction p {
  max-width: 80%;
}

.login-card {
  max-width: 400px;
}

.card {
  border: 0;
  box-shadow: 0 0 3px rgba(0,0,0,.15);
  border-radius: 0px;
}

.btn {
  font-weight: 600;
}

div#auth-logo {
  max-width: 300px;
  text-align: center;
  margin-bottom: 1.5rem;
}

#auth-logo img {
  max-width: 80px;
}

.btn-transparent {
  border: solid 1px rgba(255,255,255,.5);
}

.number {
  background: #9561e2;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  font-weight: 600;
}

label.is-invalid {
  margin-top: 1.2em;
  margin-bottom: 0;
  font-size: .8em !important;
  color: #e3342f;
}

#consent-error {
  display: none !important;
}

.text-danger a {
  color: #e3342f !important;
}

.user-summary-box {
  background: #325ad6;
  color: #fff;
  border-radius: 5px;
  padding: 15px;
  margin-top: 10px;
}

.user-summary-box a {
  color: #fff;
}

.user-summary-box h6 {
  font-size: 90%;
  margin-bottom: 0;
}

.user-summary-box .value {
  font-size: 1.9em;
  font-weight: 600;
  color: #fff;
  margin-top: .25em;
  margin-bottom: .05em;
  line-height: 1;
}

.user-summary-box .text-muted {
  color: rgba(255,255,255,.9) !important;
}

.user-summary-box .value+* {
  font-size: 90%;
}

.user-summary-box h6 i {
  font-size: 80%;
  color: rgba(255,255,255,.8);
}

.user-summary-box .sale-profit-box {
  margin-top: 1.5rem;
}

@media screen and (min-width: 340px) {
  .user-summary-box {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .nav-tabs {
    flex-flow: row wrap;
  }
}

.avatar {
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #eee;
  border-radius: 100%;
  text-align: center;
  line-height: 70px;
  margin-right: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
}

#paper_rules_checkbox-error {
  display: none;
}

html {
  position: relative;
}

body {
  color: #333;
}

.btn {
  border-radius: 8px;
}

.table-bordered {
  border-radius: 5px;
}

.policy ol {
  counter-reset: item;
  text-align: justify;
  padding-left: 0;
}

.policy li {
  display: block;
}

.policy li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.policy>ol>li {
  text-align: center;
  font-weight: 600;
  margin-bottom: 2em;
  font-size: 1.2em;
  margin-left: 0;
  padding-left: 0;
}

.policy ol {
  margin-left: 0;
  padding-left: 0;
}

.policy>ol>li ol {
  margin-top: 1em;
  font-size: 14px;
}

.policy li {
  text-align: left;
  font-weight: 400;
  font-size: 1em;
  padding-left: 0;
  margin-left: 0;
  text-align: justify;
  margin-bottom: 0;
}

.navbar-toggler {
  border: 0;
}

.btn-full-width {
  width: 100%;
}

.below-page-title {
  margin-top: -1.5em;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.8);
}

.btn-orange-slim {
  background: #ea4c89;
  color: #fff;
  border: solid 1px #ea4c89;
}

.btn-orange-slim:focus,
.btn-orange-slim:hover {
  color: #fff;
  background: #ea4c89;
}

#loader .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: rgba(0,0,0,.1);
  outline: 0;
  box-shadow: 0 0 0 .1rem rgba(0,0,0,.1);
}

.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
}

.contact-form-container .row {
  margin: 0 -0.25em;
}

.contact-form-container div[class*=col-] {
  padding: .25em;
}

.contact-form-container textarea {
  min-height: 10em;
  margin-bottom: 1em;
}

.contact-form-container .alert>ul {
  margin: 0;
  padding-left: 20px;
}

.modal-backdrop.show {
  opacity: .7;
}

.modal-backdrop {
  background-color: #ededed;
}

.modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0;
}

.modal-content {
  box-shadow: 0 0 5px rgba(0,0,0,.18);
  border: 0;
}

#catalog-filter .modal-dialog {
  max-height: 80vh;
  max-width: none;
}

#catalog-filter .modal-content {
  padding: 30px;
  width: 1000px;
}

#catalog-filter .modal-subcategories {
  columns: 3;
  overflow-y: auto;
  max-height: calc(80vh - 133px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1em;
}

#catalog-filter .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 0;
  z-index: 1000000;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.modal-title {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
}

.modal-header,
.modal-footer {
  border: 0;
}

.modal-footer {
  padding-top: 0;
  padding-bottom: 2rem;
  justify-content: flex-start;
}

.modal-body {
  padding: 0 1rem;
}

.modal-header {
  padding-top: 2rem;
}

.modal-header,
.modal-footer,
.modal-body {
  padding-right: 2rem;
  padding-left: 2rem;
}

.sms-content {
  display: flex;
  justify-content: center;
  margin: 1em auto;
}

.sms-content span {
  background: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  padding: .4rem .4rem .2rem;
  line-height: 1;
  min-width: 1em;
  margin-right: .1em;
  border: solid 1px #f0f0f0;
}

.header-search {
  margin-top: 2px;
  position: relative;
}

.header-search input[type=text] {
  width: 100%;
  border-radius: .25rem;
  padding-left: 17px;
  line-height: 1;
  height: 50px;
  font-weight: 600;
  line-height: 50px;
  background: #f3f3f4;
  border-radius: 8px;
  border: solid 1px #f3f3f4;
}

.header-search button[type=submit] {
  border-radius: 0 .25rem .25rem 0 !important;
  margin-left: -0.25rem;
  margin-left: -0.25rem;
  border: solid 1px #f1f1f1;
  height: 52px;
  border-left: 0;
  color: #3490dc;
  background: #235ad6;
  color: #fff;
  border: none;
}

.header-buttons .btn {
  font-weight: 700;
  margin-left: .7em;
  padding: .5rem .75rem;
}

.header-buttons .regular-link {
  color: #6c757d;
  padding: 0 .5rem;
  font-weight: 600;
}

.btn-transparent-green {
  color: #38c172 !important;
  border-color: #38c172;
  font-weight: 600 !important;
  background: transparent !important;
}

#login-logo {
  max-width: 100px;
}

#empty_shopping_cart {
  line-height: 1.5;
  padding: 9rem 0 5rem;
}

#empty_shopping_cart i {
  display: block;
  font-size: 70px;
  color: #40c171;
  margin-bottom: 20px;
}

.credits-table {
  margin: 0em 0;
}

.credits-table td {
  padding: 0 15px !important;
  height: 70px;
}

.table-bordered.credits-table td {
  border: solid 1px #eee !important;
  cursor: pointer;
}

.credits-table tr:hover,
.credits-table tr.active {
  background: rgba(50,90,214,.04) !important;
}

.credits-table .gift-box {
  width: 150px;
  color: green;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  letter-spacing: -0.01em;
}

.credits-table label {
  margin: 0;
  font-weight: 600;
}

.two-tabs-box {
  display: flex;
}

.tiny-container {
  max-width: 400px;
  margin: 0 auto;
}

.two-tabs-box li {
  width: 50%;
  text-align: center;
}

.two-tabs-box li a {
  margin: 0 !important;
}

.social-login {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5em;
}

.social-login a {
  width: 49%;
  padding: .7rem;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  position: relative;
  text-align: center;
}

.social-login :hover {
  text-decoration: none;
  opacity: .9;
}

.social-login .facebook-login {
  background: #3b5998;
}

.social-login .google-login {
  background: #dd4b39;
}

.social-login .facebook-login i,
.social-login .google-login i {
  position: absolute;
  left: 10px;
  top: 12px;
  font-size: 25px;
}

.mobile-buttons {
  margin: 1.5em 0;
}

#homepage-intro {
  background: linear-gradient(135deg, #f8fafb 0%, white, 50%, white 100%);
}

@media screen and (min-width: 992px) {
  #homepage-intro {
    background: linear-gradient(0deg, #f8fafb 0%, white, 50%, white 100%);
  }

  .single-product {
    margin-top: -10px;
  }
}

#homepage-intro nav {
  padding: 1rem 0 2rem;
}

#homepage-intro .illustration {
  text-align: right;
}

#homepage-intro .frontpage-banner-heading {
  font-weight: 800;
  letter-spacing: -0.05em;
  font-size: 45px;
  font-size: 40px;
  max-width: 80%;
  margin-bottom: .5em;
  color: #38c172;
}

#homepage-intro .frontpage-banner-slogan {
  font-size: 20px;
  max-width: 80%;
  font-size: 1.1rem;
}

#homepage-intro .frontpage-banner-upload {
  align-self: top;
}

#homepage-intro .illustration img {
  height: 480px;
}

#homepage-intro .dropzone-select {
  margin-top: 3em;
  margin-top: 2em;
  background: transparent !important;
  border-radius: 5px !important;
  border-width: 2.5px !important;
  border-color: #3490dc !important;
}

.btn-orange {
  background: #fa6041;
  color: #fff;
}

.btn-orange:focus,
.btn-orange:hover {
  color: #fff;
  background: #ff8c4d;
}

.btn-bordered {
  border: solid 1px #ccc;
  font-weight: 400 !important;
  color: #6c757d !important;
}

#homepage-features,
#final-upload {
  background: #fff;
  border: solid 2px #eaf3fb;
  border-width: 3px 0;
  color: #4c4c4c;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;
}

#homepage-features i {
  font-size: 40px;
  vertical-align: middle;
  margin-right: 10px;
}

#homepage-features .features-row {
  align-items: center;
}

#homepage-reviews {
  background: #d65338;
  padding: 2rem 0;
  margin-top: 6rem;
  color: #fff;
}

.semi-medium-container {
  padding: 0 65px;
}

.frontpage-testimonials {
  margin-top: 60px;
}

.frontpage-testimonial {
  text-align: center;
  color: #fff;
  padding: 1rem;
}

.testimonial-avatar {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  display: inline-block;
  background: #fff;
  border-radius: 100%;
  position: relative;
}

.testimonial-avatar img {
  max-height: 110px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  vertical-align: bottom;
}

.frontpage-testimonial p {
  margin: 1rem 0;
}

.testimonial-author-name {
  font-weight: 600;
  font-size: 1.1rem;
}

#homepage-papers {
  margin-top: 5rem;
}

.homepage-paper .price {
  font-weight: 600;
  margin-top: .2rem;
}

.homepage-paper {
  margin-bottom: 1em;
}

.homepage-paper img {
  max-width: 100%;
  border: solid 2px rgba(204,232,240,.38);
  border-radius: 5px;
  margin-bottom: .5rem;
}

.latest-paper {
  display: flex;
  align-items: center;
  max-width: 350px;
  margin-bottom: 1em;
}

.latest-paper img {
  width: 80px;
  height: auto;
  border: solid 2px rgba(204,232,240,.38);
  margin-right: 10px;
  align-self: center;
}

#final-upload {
  margin-bottom: -10rem;
  margin-top: 4rem;
}

.upload-files-modal .modal-dialog {
  min-width: 360px;
  max-width: 500px;
  width: 80%;
}

.homepage .dropzone-previews {
  margin: .7rem 0;
}

.homepage .dropzone-previews .alert-light {
  background: #f7f7f7;
}

.alert-light {
  background: #f7f7f7;
}

.documentation {
  background: #fff;
}

.documentation p,
.documentation table,
.documentation ul {
  font-size: 1.1rem;
}

.card-grey {
  background: #f1f1f1;
}

.documentation h1 {
  font-weight: 600;
  margin-bottom: .6em;
}

.documentation h2.large {
  font-size: 2.5em;
  margin-top: 1.5em;
}

.documentation h2 {
  font-weight: 600;
  margin: 1.3em 0 .8em 0;
}

.documentation h3 {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.documentation h4 {
  margin-top: 1.5em;
  margin-bottom: .8em;
}

.documentation .features h3 {
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: .5em;
}

.documentation .card {
  font-size: .95rem;
}

.documentation .card p:last-child {
  margin-bottom: 0;
}

.documentation .step {
  display: inline-block;
  text-align: center;
  color: #fff;
  background: #d63878;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: Center;
  margin-right: 10px;
  border-radius: 5px;
}

@media screen and (min-width: 80em) {
  .documentation {
    padding-left: 1rem;
  }
}

.article-nav-container {
  flex-basis: 280px;
}

.article {
  flex-basis: calc(100% - 300px);
}

.article-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1rem;
}

.article-nav ul li {
  padding: .5rem 0;
}

.article-nav a {
  color: #868686;
  font-weight: 600;
  font-size: 90%;
  text-decoration: none;
  padding: .2em 1em;
}

.article-nav a:hover,
.article-nav a.active {
  color: #000;
}

@media (min-width: 567px) {
  .affix {
    position: fixed;
    top: 107px;
    margin-top: 0;
  }

  .article-nav-container .affix {
    top: 127px;
  }
}

.affix-top {
  position: static;
}

.affix-bottom {
  position: relative;
}

.section-title {
  font-weight: 700;
  font-size: 1.45rem;
  padding-bottom: .5rem;
  padding-bottom: .3rem;
}

.single-product .catalog-item header {
  margin-bottom: 0;
}

.single-product .ratings-block {
  margin-top: 0em;
  font-size: 1.3em;
  line-height: 40px;
  display: flex;
}

.single-product .author-rating {
  margin-left: 40px;
  font-size: .9rem;
}

.single-product .rating-count {
  font-size: .9rem;
  margin-left: 8px;
}

.single-product header {
  margin-bottom: 2.5em;
  font-size: 16px;
}

.single-product header a {
  font-weight: 600;
}

.single-product header a:hover {
  text-decoration: underline;
}

.single-product h1 {
  font-weight: 800;
  margin-bottom: .5em;
  max-width: 95%;
}

.single-product .top-links {
  margin-bottom: 1em;
}

.single-product .top-links a {
  color: #6c757d;
}

.single-product .top-links a:not(:last-of-type):after {
  content: "/";
  margin: 0 .5rem;
}

.single-product .card-body,
.single-product .card-content {
  padding: 0rem;
}

.single-product .card-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.single-product .card p {
  margin-bottom: .6rem;
  font-size: 16px;
}

.single-product .card p:empty {
  margin: 0;
}

.single-product .card {
  font-size: 1rem;
  border-radius: .35rem;
  box-shadow: none !important;
}

.single-product .card-content h3 {
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 1em;
}

.toc {
  border: 0;
  margin: 0;
  padding: 0 !important;
  list-style: none;
  position: relative;
  font-size: 16px;
}

.dots {
  width: 100%;
  padding: 3px 0;
  overflow: hidden;
}

.dots span {
  display: inline;
  background: #fff;
  z-index: 2;
  position: relative;
}

.dots span.page-number {
  float: right;
}

.dots:after {
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  color: #c3c3c3;
  content: " ................................................................................................................................................................................................................................................................................................................................................................................................................................................";
}

.single-sidebar {
  flex: 0 0 360px;
  max-width: 360px;
}

.paper-content {
  flex: 0 0 calc(100% - 450px);
  padding-right: 48px;
  max-width: none;
}

#paper-purchase-menu {
  width: 330px;
}

#paper-purchase-menu .read-excerpt {
  background: rgba(35,90,214,.85);
  display: inline-block;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  top: 33%;
  font-weight: 600;
  right: -5px;
  transition: all 300ms;
  z-index: 20;
  border-radius: 2px 0 0 2px;
  font-size: 1.05rem;
  z-index: 20;
}

#paper-purchase-menu .read-excerpt:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 0 5px;
  border-color: transparent transparent transparent #31497f;
  right: 0px;
  top: -5px;
}

#paper-purchase-menu figure img {
  position: relative;
  box-shadow: 0px 0px 5px rgba(0,0,0,.1);
  max-height: calc(100vh - 300px);
  min-height: 200px;
  border-radius: .5rem;
}

#paper-purchase-menu figure {
  position: relative;
  text-align: left;
  max-width: 100%;
}

#paper-purchase-menu figure a {
  z-index: 10;
  display: inline-block;
  text-transform: lowercase;
  position: relative;
  cursor: zoom-in;
  color: #3e3e3e;
  font-size: 1em;
  text-decoration: none;
}

#paper-purchase-menu figure a:hover .read-excerpt {
  background: #235ad6;
}

#paper-purchase-menu figure a .arrow-down {
  position: relative;
  top: 1px;
}

.single-paper-price {
  margin: 0 0 .5em;
  color: #000;
  font-weight: 700;
  font-size: 2em;
}

.initials {
  height: 30px;
  background: #f1f1f1;
  color: #6c757d;
  width: 30px;
  border-radius: 100%;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  margin-right: 4px;
}

.review {
  margin-bottom: 1em;
  border-bottom: solid 1px #eee;
  padding-bottom: 1em;
}

.review .author {
  font-weight: 1em;
  font-weight: 600;
}

.review .date {
  margin-top: .3em;
  font-size: 80%;
  color: #868686;
}

.review:last-of-type {
  border-bottom: 0;
}

.golden {
  color: #a77d06;
}

.initials.small {
  height: 25px;
  width: 25px;
  line-height: 26px;
  margin-right: 3px;
}

.author-rating .rating {
  margin-left: 10px;
}

#paper-images {
  padding-right: 0px;
  padding-top: 5px;
  margin: 0 auto;
  max-width: 700px;
  margin-bottom: 100px;
}

.paper-preview .modal-dialog {
  transform: none !important;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  left: 0;
  top: 75px;
}

#paper-images img {
  max-width: 100%;
  width: 100%;
  backface-visibility: hidden;
}

.paper-images-footer {
  background: #f1f1f1;
  padding: 10px;
}

.responsive-video {
  position: relative;
  margin: 0;
  max-width: 800px;
  height: 0;
  padding-top: 56.25%;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  max-width: 800px;
  margin: 0 auto;
}

#video-container {
  margin-top: -2.5em;
  background: #167ac6;
  padding: 20px;
}

.system-content {
  font-size: 1.03rem;
}

.system-content h2 {
  margin-bottom: 1em;
}

.md-navbar+.hero {
  margin-top: -2.5em;
  background: #fff;
}

.display-4 {
  margin-bottom: .5em;
}

.accordion .card-header {
  background: #fff;
}

.accordion .collapse.show,
.accordion .collapsing {
  border-bottom: solid 1px #ddd;
}

#duk .btn {
  text-align: left;
}

.main-box {
  min-height: calc(100vh - 700px);
}

.single-product .annotation {
  margin: 1em 0 1em;
  font-size: 1rem;
  font-style: italic;
  border: dashed 1px #ddd;
  border-width: 1px 0;
  padding: 15px 0;
}

.quick-facts {
  display: flex;
}

.quick-facts dl {
  border: solid 1px #eee;
  display: flex;
  flex: 1;
  flex-flow: row wrap;
}

.quick-facts dl dt {
  padding: 0 1em;
  flex-basis: 33.33%;
  background: #fbfbfb;
  border-bottom: solid 1px #ebebeb;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  margin-bottom: 0;
  border-right: solid 1px #ebebeb;
}

.quick-facts dl dd {
  padding: 0 1em;
  flex-basis: 66.66%;
  border-bottom: solid 1px #ebebeb;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  margin-bottom: 0;
}

#paper-preview-modal .modal-content {
  box-shadow: none;
  background: transparent;
}

#paper-images img {
  box-shadow: 0 0 3px #ddd;
  margin-bottom: .5em;
}

.single-product .modal-backdrop {
  background: #000;
}

.paper-images-header {
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 100000;
  padding: 15px 0 10px;
  line-height: 1.2;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
}

.btn.text-muted {
  font-weight: 400;
}

#loading-modal .modal-content {
  padding: 60px;
  text-align: center;
  border: 0;
}

#loading-modal .modal-content i {
  font-size: 4em;
  color: #235ad6;
  margin-bottom: .35em;
}

@media screen and (max-width: 767px) {
  #homepage-intro .frontpage-banner-heading {
    font-size: 30px;
  }

  .homepage h2 {
    font-size: 1.4rem;
  }

  .homepage .mt-5 {
    margin-top: 2rem !important;
  }

  .homepage .card-title {
    font-size: 1.2rem;
  }

  #homepage-reviews {
    margin-top: 3rem;
  }

  #homepage-reviews .mb-5 {
    margin-bottom: 1.5rem !important;
  }

  .frontpage-testimonials {
    margin-top: 30px;
  }

  #homepage-papers {
    margin-top: 50px;
  }

  #homepage-papers .mb-5 {
    margin-bottom: 2rem !important;
  }

  #final-upload {
    margin-top: 1rem;
  }
}

.about-us {
  border-radius: 0;
  margin-top: -48px;
}

.featured-heading {
  position: relative;
  display: inline-block;
  font-weight: 700 !important;
  margin-bottom: 5rem !important;
  letter-spacing: -0.03em;
  font-size: 2.7rem;
}

.featured-heading:after {
  content: "";
  position: absolute;
  width: 100%;
  background: #d63878;
  width: 100%;
  height: 6px;
  bottom: -10px;
  border-radius: 8px;
  left: 0;
}

.documentation h3 {
  font-weight: 700;
}

.documentation .icon {
  width: 3.5rem;
  height: 3.5rem;
  background: rgba(19,171,26,.11);
  line-height: 3.5rem;
  text-align: center;
  border-radius: 10px;
}

.documentation .icon i {
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: #063308;
}

body:not(.homepage):not(.login-page) {
  margin-top: 127px;
}

@media screen and (max-width: 767px) {
  body:not(.homepage):not(.login-page) {
    margin-top: 103px;
  }

  .mobile-search {
    margin-top: -40px;
  }
}

.paper-type a {
  color: #6c757d;
}

.service-testimonial {
  padding: .8rem 0;
  background: #fafafa;
  padding: 1.5em;
  margin-bottom: .8em;
}

.service-testimonial .comment {
  font-size: 100%;
}

.service-testimonial .name {
  font-weight: 600;
  color: #000;
}

.service-testimonial:last-of-type {
  border-bottom: 0;
}

.cart-sidebar {
  padding-left: 60px;
}

.checkout-sidebar-block {
  padding: 30px 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0,0,0,.15);
  margin-bottom: 1.5em;
  border: 0;
}

.old-price {
  text-decoration: line-through;
}

.user-content {
  font-size: .9rem;
}

.user-content h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0 .8rem;
}

.affiliate-tos {
  max-height: 80vh;
  overflow-y: scroll;
}

.affiliate-tos .table-sm th,
.affiliate-tos .table-sm td {
  padding: .3rem;
}

.dataTables_wrapper .pagination {
  justify-content: flex-end;
  margin-top: .2em;
}

.statement-links a:not(:last-of-type):after {
  content: "|";
  color: #6c757d;
  margin-left: .3em;
}

.statement-links a {
  margin-right: .3em;
}

.small-preview {
  width: 637px !important;
  max-width: none !important;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.small-preview img {
  width: 209px !important;
}

.mobile-purchase-box {
  height: 50px;
  position: fixed;
  bottom: 0;
  background: #38c172;
  width: 100%;
  z-index: 2;
  box-shadow: 0 0 5px 1px #ddd;
}

.mobile-single-buttons {
  display: block;
  color: #fff;
  line-height: 50px;
  text-align: center;
  padding: 0;
  border: none !important;
}

.mobile-add-to-cart.activated:focus>span .mobile-add-to-cart.activated:active>span,
.mobile-add-to-cart.activated:hover>span {
  display: none;
}

.mobile-add-to-cart .loading {
  display: none;
}

.mobile-add-to-cart.activated:focus>.loading,
.mobile-add-to-cart.activated:hover>.loading {
  display: inline-block;
}

.mobile-single-preview {
  background: #fff;
  color: #777;
}

.mobile-single-preview:focus,
.mobile-single-preview:hover,
.mobile-add-to-cart:hover,
.mobile-add-to-cart:focus {
  text-decoration: none;
}

.mobile-add-to-cart:focus,
.mobile-add-to-cart:hover {
  color: #fff;
}

.payment-promoter {
  background: #235ad6;
  color: #fff;
  margin-top: .4em;
  padding: 1em;
  border-radius: 5px;
  text-align: center;
}

.payment-promoter .secure-payment .large {
  display: block;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
}

.payment-promoter .secure-payment .small {
  font-size: .8em;
  text-transform: uppercase;
}

.mobile-count {
  background: #d43776;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 25px;
  top: 8px;
  transform: scaleX(-1);
}

.cart-table {
  box-shadow: 0 1px 3px rgba(0,0,0,.15);
  border-radius: .35rem;
}

.cart-table a {
  color: #000;
}

.cart-table th {
  background: #fafafa;
}

.cart-table .paper-image {
  padding: 0 0 0 15px !important;
}

.payment-promote-box {
  margin-top: 3em;
  border-bottom: solid 1px #e5e5e5;
  padding-bottom: 2em;
}

.payment-promote-box h5 {
  margin-bottom: 0;
}

.payment-promote-box .checkpoint {
  display: flex;
}

.payment-promote-box .checkpoint .icon {
  width: 40px;
  content: "";
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  float: left;
  height: 40px;
  flex-shrink: 0;
  margin-right: 15px;
  color: #fff;
  background: #c74cb1;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  font-size: 25px;
}

.discount-promo {
  display: flex;
  align-items: center;
  line-height: 1.1;
}

.discount-promo i {
  margin-right: 20px !important;
  display: inline-block;
}

.user-page body {
  margin-top: 0 !important;
}

.user-page .navbar {
  position: relative;
  margin-bottom: 0;
}

.user-page .user-nav {
  padding: .4rem 0;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
}

.user-page .user-nav a {
  font-weight: 600;
  margin-right: 25px;
  color: #6c757d;
  font-size: 95%;
}

.user-page .user-nav a.active,
.user-page .user-nav a:focus {
  color: #000;
  background-color: transparent;
}

.user-page .user-nav .navbar-nav {
  flex-flow: row;
}

.paper-extension-icon {
  width: .6em !important;
}

.cart tfoot td {
  border: 0;
}

.price-column {
  padding: 0 10px !important;
}

.credits-column {
  padding: 0 5px !important;
}

.cart th {
  vertical-align: middle !important;
}

span.label {
  background: green;
  color: #fff;
  display: inline-block;
  padding: 0px 5px;
  margin-left: 5px;
  border-radius: 5px;
}

.credit-price {
  font-size: 28px;
  color: #325ad6;
  line-height: 1;
  background: #fff;
  margin-bottom: .2em;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 0px 9px;
  border: solid 1px #eee;
  margin-bottom: .5em;
}

.credit-price .amount {
  letter-spacing: 1px;
  margin-left: 3px;
  font-weight: 600;
  border: 0;
  width: 70px;
  font-size: 28px !important;
  color: #325ad6;
}

.credit-price .currency {
  font-size: 50%;
  vertical-align: super;
}

.credit-price .per-credit {
  font-size: 50%;
  vertical-align: bottom;
  line-height: 1;
  position: relative;
  top: -4px;
  left: -9px;
}

.seperator {
  margin-bottom: 32px;
}

.my-order-sidebar {
  border-left: solid 1px #f3f3f4;
  padding-left: 40px;
  padding-top: 30px;
  padding-bottom: 100px;
}

.checkout-page {
  margin-top: -2.75rem;
}

.checkout-page h5 {
  font-weight: 600;
}

.checkout-content {
  padding-top: 1.75rem;
  padding-right: 2.75rem;
  padding-bottom: 130px;
}

.order-items {
  max-height: 160px;
  overflow-y: auto;
}

.order-item {
  display: flex;
  border-radius: 8px;
  background: #f3f3f4;
  padding: 15px;
  line-height: 1.2;
  align-items: center;
  margin-bottom: 10px;
}

.order-item .rating {
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.order-item .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.order-item .title a {
  color: #000;
}

.order-item figure {
  width: 30px;
  margin-right: 10px;
  margin-bottom: 0;
  flex-shrink: 0;
}

.order-item figure img {
  max-width: 100%;
  border: solid 1px #f3f3f4;
}

.order-item main {
  width: calc(100% - 100px);
}

.order-item .price {
  width: 80px;
  text-align: right;
}

.paper-ad a {
  color: #ea4c89;
}

.totals {
  font-size: 1.5rem;
  font-weight: 700;
}

.order-items.scroll-activated {
  padding-right: 15px;
}

.order-item.mobile {
  background: #fff;
  padding: 0;
}

.order-item.mobile main {
  width: 100%;
}

.order-item.mobile .price {
  float: none;
  text-align: left;
}

.order-item.mobile .price div {
  display: inline;
}

.order-item.mobile:last-of-type {
  margin-bottom: 0;
}

.checkout-content .card {
  border-radius: 8px;
  padding: 5px;
}

.checkout-content .card h5 {
  font-weight: 600;
}

.checkout-content .card p {
  line-height: 1.2;
}

.form-control {
  border-radius: 8px;
  height: calc(1.6em + 0.9rem + 2px);
  padding: .45rem .75rem;
}

.btn:not(.btn-sm) {
  padding: .45rem .75rem;
}

.btn-secondary {
  color: #0d0c22;
  background-color: #f3f3f4;
  border-color: #f3f3f4;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #e7e7e9;
  border-color: #f3f3f4;
  color: #0d0c22;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  background-color: #e7e7e9;
  border-color: #f3f3f4;
  color: #0d0c22;
  box-shadow: 0 0 0 .2rem rgba(130,138,145,.2) !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 .2rem 0em rgba(130,138,145,.05) !important;
}

.card.inactive {
  color: #888;
  background: #fafafa;
}

.card.inactive .info-group {
  display: none;
}

.result-group {
  display: none;
  margin-bottom: -5px;
}

.result-group>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card.has-data .info-group {
  display: none;
}

.card.has-data .result-group {
  display: block;
  margin-bottom: -5px;
}

#payment-box .result-group img {
  max-height: 45px;
  margin-top: 5px;
}

.card .edit-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.checkout-content .payment-methods {
  margin: 0 -3px;
}

.checkout-content .payment-methods>* {
  padding: 3px;
}

.checkout-content .card-body:after {
  transition: all 300ms;
}

.blackout:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #000;
  opacity: .05;
}

.static-header {
  position: absolute;
}

.payment-info {
  background: #2f53ad;
  color: #fff;
  margin-bottom: -10em;
}

.checkout-page.mobile {
  margin-top: -1.5em;
}

.payment-info {
  padding: 20px;
}

.payment-info hr {
  background: #fff;
  color: #fff;
  opacity: .2;
}

.payment-info .service-testimonial {
  background: #fff;
  border-radius: 8px;
  margin: 10px;
}

.payment-info .service-testimonial .rating {
  font-size: 17px;
}

.payment-info .service-testimonial .comment {
  color: #222;
}

.payment-info .flex {
  display: flex;
}

.payment-info .flex>* {
  flex: 1;
  padding-bottom: 5px;
}

.payment-info p {
  margin: 0;
}

.payment-info h5 {
  margin-bottom: 0;
}

.payment-info i {
  margin-right: 5px;
}

@media screen and (max-width: 1199px) {
  .checkout-page-desktop {
    max-width: none;
    min-width: 1000px;
    padding: 0 20px;
  }
}

.payment-info.mobile {
  margin-bottom: 0;
  margin-top: 3em;
  border-radius: 8px;
  background: #f3f3f4;
  color: #636363;
  margin-bottom: 0;
  margin-top: 3em;
  border-radius: 8px;
}

.payment-info.mobile .service-testimonial {
  padding: 15px;
}

#credits-ad {
  background: #fff;
  color: #23272b;
  margin-top: 100px;
  font-weight: 400;
  margin-bottom: -145px;
  text-align: center;
  padding: 50px 40px 40px;
  font-size: 1.3rem;
  box-shadow: 0 0 2px rgba(0,0,0,.2);
}

#credits-ad .icon {
  font-size: 2.5rem;
  color: #235ad6;
  margin-bottom: 1rem;
}

#credits-ad a {
  display: inline-block;
  margin: 0 auto;
  margin-top: 1.2rem;
  border: 0;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 8px;
}

@media screen and (max-width: 991px) {
  #credits-ad {
    margin-bottom: -100px;
  }
}

@media screen and (min-width: 768px) {
  .statement-operation-type {
    max-width: 320px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .75rem;
    padding-left: .75rem;
  }

  #sortBy {
    width: 170px;
    position: absolute;
    right: 15px;
    bottom: 7px;
  }
}

@media (min-width: 1366px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (min-width: 1500px) {
  .container {
    max-width: 1300px;
  }
}

@media screen and (max-width: 991px) {
  #login-form input {
    font-size: 16px;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .container {
    padding: 0 1rem !important;
  }

  .frontpage-offer {
    padding: 3rem 1rem .5rem !important;
  }

  #homepage-intro {
    background: #38c172;
    background: linear-gradient(15deg, #38c172 0%, #2cd272 100%);
    background: #235ad6;
  }

  #homepage-intro nav {
    padding: 1rem 0 .5rem;
    background: #fff;
  }

  #homepage-intro .frontpage-banner-heading {
    color: #fff;
  }

  #homepage-intro .frontpage-banner-slogan {
    color: rgba(255,255,255,.9);
  }

  #homepage-features {
    text-align: center;
    padding: 3rem 0 2rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: #14203c;
  }

  #homepage-features .features-row>* {
    margin-bottom: 1em;
  }

  #homepage-features i {
    font-size: 1.8rem;
    color: #235ad6;
  }

  #how-it-works .card {
    margin-bottom: 1em;
  }

  .homepage-paper {
    padding-bottom: 1em;
  }

  .homepage-paper:not(.last) {
    border-bottom: dashed 1px rgba(20,32,60,.23);
  }

  .homepage-paper img {
    display: none;
  }

  .navbar>.container,
  .navbar>.container-fluid {
    display: block;
    position: relative;
  }

  .navbar-brand {
    text-align: center;
    width: auto;
    margin: 0 auto;
    display: inline-block;
  }

  .static-top {
    text-align: center;
  }

  .navbar-toggler {
    position: absolute;
    left: 10px;
    top: 7px;
  }

  #mobile-cart-toggler {
    position: absolute;
    right: 10px;
    top: -2px;
    padding: 10px;
    font-size: 1.3rem;
    color: #c8caca;
    transform: scaleX(-1);
  }

  .navbar-collapse {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    width: auto;
    overflow-x: hidden;
    padding: 10% 3% 100px 15px;
    padding-top: 100px;
    height: 100vh;
    max-width: 270px;
    overflow-y: auto;
    z-index: 10000000;
    box-shadow: 0 0 50px rgba(0,0,0,.1490196078);
  }

  .navbar-collapse a:not(.btn) {
    display: block;
    font-size: 1rem;
    margin: 0 !important;
    color: #6c757d !important;
    text-align: left;
    border: 0;
    font-weight: 400 !important;
    padding: .4em 0 !important;
    color: #6c757d !important;
    white-space: nowrap;
  }

  .login-button {
    margin-left: 0 !important;
  }

  .navbar-collapse a.active {
    color: #000 !important;
  }

  .navbar-mobile-inner {
    min-width: 300px;
  }

  #close-mobile-menu {
    position: absolute;
    top: 30px;
    right: 30px;
    background: rgba(26,45,89,.13);
    font-size: 1.2em;
    white-space: nowrap;
    font-weight: 600;
    text-align: center;
    text-transform: lowercase;
    color: #2359d4;
    border: 0;
    background: transparent;
  }

  #close-mobile-menu i {
    margin-right: .3em;
  }
}

@media screen and (min-width: 576px)and (max-width: 991px) {
  .frontpage-offer {
    padding: 3rem 1rem 1.5rem !important;
  }
}

@media screen and (min-width: 992px) {
  .navbar-mobile-inner {
    width: 100%;
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  #homepage-intro .frontpage-banner-heading {
    font-size: 45px;
    max-width: none;
    margin-top: 1.5rem;
  }

  #homepage-features {
    color: #14203c;
  }

  .frontpage-offer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 1366px) {
  .frontpage-offer {
    padding-left: 65px;
    padding-right: 65px;
  }
}

@media screen and (max-width: 767px) {
  #empty_shopping_cart {
    padding: 3em 0;
  }

  .modal-dialog {
    min-width: 80%;
  }

  .btn-checkout {
    font-size: 1.2em;
  }

  .cart-totals {
    padding: 0 !important;
    margin-bottom: 3em;
  }

  .cart-totals h3 {
    font-size: 1.3em;
  }

  .cart-totals h3 b {
    font-size: 1.3em;
  }

  .cart-totals .checkout-button {
    margin-top: 1.5em !important;
  }
}

@media screen and (max-width: 480px) {
  .modal-dialog {
    min-width: 90%;
  }

  .page-title {
    font-size: 1.6em;
    margin-top: -0.5em;
  }

  .page-title+p {
    margin-top: -1em;
  }

  .cart {
    line-height: 1.3;
  }

  .cart .paper-image {
    width: 0;
    padding: 0 !important;
    min-width: 0;
    max-width: 0;
    padding: 0;
    border: 0;
  }

  .cart .paper-image img {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .display-4 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 991px) {
  .paper-content {
    width: 100%;
    display: block;
    flex: none;
    padding-right: 15px;
  }

  .single-product h1 {
    max-width: none;
  }

  .single-product {
    margin-top: 0;
  }

  .paper-content {
    margin-top: 25px;
  }

  .paper-images-header {
    background: transparent;
    text-align: left;
    background: #e1e1e1;
    color: #6c757d;
    padding-top: 8px;
    top: 0;
    font-size: .9rem;
  }

  .paper-preview .modal-dialog {
    top: 90px;
  }
}

@media screen and (max-width: 767px) {
  #paper-images {
    max-width: 100%;
    padding: 0 20px;
  }

  .single-product h1 {
    font-size: 1.6rem;
  }

  .single-product .paper-type {
    font-size: 1rem;
  }

  .single-product .top-links {
    margin-bottom: 1em;
  }

  .paper-preview .prezentacija {
    width: 150px;
  }

  .section-title {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: .2em;
  }

  .single-product .ratings-block {
    display: block;
    margin-top: 0rem;
  }

  .single-product .author-rating {
    margin-left: 0;
    font-size: .9rem;
  }

  .single-product header {
    margin-bottom: 1.2em;
  }

  .single-product ul,
  .single-product ol,
  .single-product p {
    font-size: .9rem !important;
  }

  .single-product .quick-facts {
    font-size: .9rem;
    display: block;
  }

  .single-product .quick-facts dl {
    margin-bottom: 0px;
  }

  .single-product .quick-facts dl:nth-child(1) {
    border-bottom: 0;
  }

  .single-product .quick-facts dl:nth-child(2) {
    border-top: 1px;
  }

  .single-product .reviews .card {
    font-size: .9rem;
  }

  .small-preview {
    width: 249px !important;
    display: block;
    flex-flow: wrap;
  }

  .small-preview img {
    width: 100% !important;
    max-width: 249px !important;
  }

  .prezentacija .small-preview {
    width: 346px !important;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .prezentacija .small-preview img {
    width: 49.5% !important;
    max-width: 150px !important;
  }
}

@media screen and (min-width: 992px)and (max-width: 1199px) {
  .navbar-brand {
    max-width: 40px;
    overflow: hidden;
  }

  .paper-content {
    flex: 0 0 calc(100% - 300px);
    padding-right: 60px;
    max-width: none;
  }

  .single-sidebar {
    flex: 0 0 260px;
    max-width: 260px;
  }

  #paper-purchase-menu {
    width: 260px;
  }

  .single-product h1 {
    max-width: 95%;
  }
}

@media screen and (min-width: 1200px)and (max-width: 1300px) {
  .paper-content {
    flex: 0 0 calc(100% - 360px);
    padding-right: 60px;
    max-width: none;
  }

  .single-sidebar {
    flex: 0 0 280px;
    max-width: 280px;
  }

  #paper-purchase-menu {
    width: 280px;
  }

  .single-product h1 {
    max-width: 85%;
  }
}

@media screen and (min-width: 768px) {
  .prezentacija #paper-images {
    width: 457px !important;
  }

  .prezentacija #paper-images img {
    width: 150px;
  }
}

@media screen and (max-width: 991px) {
  .page-footer {
    background: #0d2657;
    color: rgba(255,255,255,.9);
    background: #fff;
    padding: 0;
    margin-top: 6em;
    color: #222;
    box-shadow: none;
    text-align: left;
  }

  .page-footer hr {
    margin-top: .5em;
    margin-bottom: 1.5em;
  }

  .page-footer .container {
    text-align: left !important;
  }

  .page-footer .top {
    border: solid 1px #ddd;
    border-width: 1px 0 0;
    padding: 30px 0 10px;
  }

  .page-footer h5 {
    text-transform: unset !important;
    color: #555;
    margin: 0 !important;
    margin-bottom: 1.2em !important;
    position: relative;
  }

  .page-footer h5:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 0;
    transition: transform 300ms;
  }

  .page-footer h5.active:after {
    transform: rotate(180deg);
  }

  .page-footer ul {
    display: none;
    margin-bottom: 30px;
    font-size: 1.05em;
    line-height: 2.5;
  }
}

@media screen and (max-width: 991px) {
  .magic-table .table thead {
    display: none;
  }

  .magic-table .table tr {
    display: block;
  }
}

.table-responsive .datatable {
  min-width: 600px;
  overflow-x: auto;
}

@media screen and (max-width: 480px) {
  .statement-table .table thead {
    display: none;
  }

  .statement-table .table tbody {
    display: block;
  }

  .statement-table .table tr,
  .statement-table .table td {
    display: block;
  }

  .statement-table .table td {
    border: none;
    padding: 0 !important;
    line-height: 1.3 !important;
  }

  .statement-table .table td:first-of-type {
    color: #6c757d;
  }

  .statement-table .table td:last-of-type {
    display: none;
  }

  .statement-table .table td:nth-child(2) {
    margin-top: .2em;
  }

  .statement-table .table td:nth-child(3) {
    font-weight: 600;
    font-size: 1.1em;
    margin-top: .5em;
  }

  .statement-table .table tr {
    border: solid 1px #eee;
    padding: 10px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 767px) {
  input,
  select {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 768px) {
  .user-summary-box .sale-profit-box {
    margin-top: 0;
  }

  .user-summary-box {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .dashboard-functions {
    padding: 0 50px;
  }

  .user-summary-box {
    margin-top: 30px;
    padding: 25px;
  }
}

@media screen and (max-width: 767px) {
  .papers-table .table thead {
    display: none;
  }

  .papers-table .table tr {
    border-bottom: solid 1px #ddd;
  }

  .papers-table .table tr td:first-of-type {
    display: none;
  }

  .papers-table .table tr td {
    display: block;
    border: 0;
    padding: 0;
  }

  .papers-table .table tr td:nth-child(2) {
    font-weight: 600;
    padding-top: 10px;
    font-size: 1.1em;
  }

  .papers-table .table tr td:nth-child(3),
  .papers-table .table tr td:nth-child(4) {
    display: inline-block;
  }

  .papers-table .table tr td:nth-child(3):before {
    content: "Pardavimai: ";
    color: #6c757d;
  }

  .papers-table .table tr td:nth-child(3):after {
    margin-left: .5em;
    content: "|";
    color: #6c757d;
  }

  .papers-table .table tr td:nth-child(4) {
    margin-left: .5em;
  }

  .papers-table .table tr td:nth-child(4):before {
    color: #6c757d;
    content: "Uždarbis: ";
  }

  .papers-table .table tr td:nth-child(5) {
    padding-top: .4em;
    font-size: 90%;
    padding-bottom: .5em;
  }

  .papers-small-table .table thead {
    display: none;
  }

  .papers-small-table .table tr {
    border-bottom: solid 1px #ddd;
  }

  .papers-small-table .table tr td:first-of-type {
    display: none;
  }

  .papers-small-table .table tr td {
    display: block;
    border: 0;
    padding: 0;
  }

  .papers-small-table .table tr td:nth-child(2) {
    padding-top: .6em;
    font-size: 1.1em;
    font-weight: 600;
  }

  .papers-small-table .table tr td:nth-child(3) {
    margin-top: .2em;
    font-size: 90%;
    padding-bottom: .4em;
  }

  .papers-small-table .table tr td:nth-child(4) {
    text-align: left !important;
    border-bottom: solid 1px #ddd;
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .article {
    flex-basis: 100%;
    width: 100%;
    max-width: none;
  }

  .documentation p,
  .documentation table,
  .documentation ul {
    font-size: 1.08em;
  }

  .featured-heading {
    font-size: 2rem !important;
  }

  .documentation h2.large {
    font-size: 2em;
  }
}

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-page .logo img {
  max-width: 50px;
}

.login-page .login-heading {
  justify-content: space-between;
  margin-bottom: .5em;
}

.login-page .signin-block {
  box-shadow: 0 0 3px #ddd;
  padding: 40px;
  background: #fff;
}

.login-page h2 {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 0em;
}

.login-page .small-container {
  width: 100%;
  min-width: 350px;
  flex-shrink: 0;
}

.login-page .form-group {
  margin-bottom: .6em;
}

.login-page .btn-social {
  border: solid 1px #ddd;
  font-weight: 400;
  color: #555;
}

.login-page .btn-social i {
  font-size: 80%;
  margin-right: .4em;
}

@media screen and (max-width: 399px) {
  .login-page {
    background: #fff;
  }
}

@media screen and (min-width: 400px) {
  .login-page .small-container {
    width: 350px;
  }
}

.btn-fb-login i {
  color: #3b5998;
}

.btn-google-login i {
  color: #db4437;
}

@media screen and (max-width: 400px) {
  .login-page .signin-block {
    border: 0;
    padding: 30px !important;
    box-shadow: none;
  }
}

@media screen and (max-width: 350px) {
  .small-container {
    min-width: 0 !important;
    width: 100% !important;
  }
}

