@import 'header';
@import 'general';
@import 'catalog';
@import 'footer';
@import 'user';
html {
	position: relative;
}
body {
	color: #333;
}
.btn {
	border-radius: 8px;
}
.table-bordered {
	border-radius: 5px;
}
.policy {
	
	ol { counter-reset: item; text-align: justify; padding-left: 0;}
	li{ display: block }
	li:before { content: counters(item, ".") ". "; counter-increment: item }
	> ol > li {
	    text-align: center;
	    font-weight: 600;
	    margin-bottom: 2em;
	    font-size: 1.2em;
	    margin-left: 0;
	    padding-left: 0
	}
	ol {
	    margin-left: 0;
	    padding-left: 0;
	}
	> ol > li ol {
	    margin-top: 1em;
	    font-size: 14px
	}
	li {
	    text-align: left;
	    font-weight: 400;
	    font-size: 1em; 
	    padding-left: 0;
	    margin-left: 0;
	    text-align: justify;
	    margin-bottom: 0;
	}

}

.navbar-toggler {
	border: 0;
}

.btn-full-width {
	width: 100%;
}
.below-page-title {
	margin-top: -1.5em;
}
#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.8);
}

.btn-orange-slim {
	background: #ea4c89;
	color: #fff;
	border: solid 1px #ea4c89;
}
.btn-orange-slim:focus,
.btn-orange-slim:hover {
	color: #fff;
	background: #ea4c89;

}

#loader .content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.1);
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
}

.contact-form-container {
	max-width: 600px;
	margin: 0 auto;
}
.contact-form-container {
	.row {
		margin: 0 -0.25em;
	}
	div[class*="col-"]  {
		padding: 0.25em;
	}
	textarea { 
		min-height: 10em;
		margin-bottom: 1em;
	}
	.alert > ul {
		margin: 0;
		padding-left: 20px;
	}
}
.modal-backdrop.show {
    opacity: 0.7;
}

.modal-backdrop {
    background-color: #ededed; 
}
.modal-dialog {
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
}
.modal-content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
    border: 0;
}
#catalog-filter {
	.modal-dialog {
		max-height: 80vh;
		max-width: none;
	}
	.modal-content {
		padding: 30px;
		width: 1000px;
	}
	.modal-subcategories {
columns: 3;
    overflow-y: auto;
    max-height: calc(80vh - 133px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 1em;
	}
	.btn-close {
		position: absolute;
		right: 20px;
		top: 20px;
		border: 0;
		z-index: 1000000;
		width: 25px;
		height: 25px;
		line-height: 25px;
	}
}
.modal-title {
	font-weight: 600;
	font-size: 1.4rem;
	line-height: 1.2;
}
.modal-header, 
.modal-footer {
	border: 0;
}
.modal-footer {
	padding-top: 0;
	padding-bottom: 2rem;
	justify-content: flex-start;
}
.modal-body {
	padding: 0 1rem;
}
.modal-header {
	padding-top: 2rem;
}
.modal-header,
.modal-footer,
.modal-body {
	padding-right: 2rem;
	padding-left: 2rem;
}

.sms-content {
    display: flex;
    justify-content: center;
    margin: 1em auto;
}
.sms-content span {
    background: #FFF;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0.4rem 0.4rem 0.2rem;
    line-height: 1;
    min-width: 1em;
    margin-right: 0.1em;
    border: solid 1px #f0f0f0;
}

.header-search {
	margin-top: 2px;
	position: relative;
}

.header-search {
	input[type=text] {
		width: 100%;
		border-radius:  0.25rem;	
		padding-left: 17px;
		line-height: 1;
		height: 50px;
		font-weight: 600;
		line-height: 50px;
		background: #f3f3f4;
		border-radius: 8px;
        border: solid 1px #f3f3f4;
	}

	button[type=submit] {
		border-radius: 0 0.25rem 0.25rem 0 !important;
		margin-left: -0.25rem;
margin-left: -0.25rem;
    border: solid 1px #f1f1f1;
    height: 52px;
    border-left: 0;
    color: #3490dc;
    background: #235ad6;
    color: #FFF;
    border: none;
	}

}

.header-buttons .btn {
	font-weight: 700;
	margin-left: 0.7em;
	padding: 0.5rem 0.75rem
}
.header-buttons .regular-link {
	color: #6c757d;
	padding: 0 0.5rem;
	font-weight: 600;
}

.btn-transparent-green {
	color: #38c172 !important;
	border-color: #38c172;
	font-weight: 600 !important;
	background: transparent !important;
}

#login-logo {
	max-width: 100px;
}

#empty_shopping_cart {
	line-height: 1.5;
	padding: 9rem 0 5rem;
}

#empty_shopping_cart {
	i {
		display: block;
		font-size: 70px;
		color: #40c171;
		margin-bottom: 20px;
	}
}
.credits-table {
	margin: 0em 0;
}
.credits-table td {
	padding: 0 15px !important;
	height: 70px;
}
.table-bordered.credits-table td {
	border: solid 1px #eee !important;
	cursor: pointer;
}
.credits-table tr:hover,
.credits-table tr.active {
    background: rgb(50 90 214 / 4%) !important;
}
.credits-table .gift-box {
	width: 150px;
	color: green;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	letter-spacing: -0.01em
}
.credits-table label {
	margin: 0;
	font-weight: 600;
}
.two-tabs-box {
	display: flex;

}
.tiny-container {
	max-width: 400px;
	margin: 0 auto;
}
.two-tabs-box {
	li {
		width: 50%;
		text-align: center;
	}
	li a {
		margin: 0 !important;
	}
}
.social-login {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5em;
}
.social-login {

	a {
		width: 49%;
		padding: 0.7rem;
		color: #FFF;
		border-radius: 4px;
		font-weight: 600;
		position: relative;
		text-align: center;
	}

	:hover {
		text-decoration: none;
		opacity: 0.9;
	}

	.facebook-login {
		background: #3B5998;
	}

	.google-login {
		background: #dd4b39;
	}

	.facebook-login i,
	.google-login i {
		position: absolute;
		left: 10px;
		top:12px;
		font-size: 25px;
	}

}

/** Homepage **/

.mobile-buttons {
	margin: 1.5em 0;
}

#homepage-intro {
background: linear-gradient(135deg, rgba(248,250,251,1) 0%, rgba(255,255,255,1), 50%, rgba(255,255,255,1) 100%);
}

@media screen and (min-width: 992px) {
	#homepage-intro {
		background: linear-gradient(0deg, rgba(248,250,251,1) 0%, rgba(255,255,255,1), 50%, rgba(255,255,255,1) 100%);
	}
	.single-product {
		margin-top: -10px;
	}
}
#homepage-intro {

	nav {
		padding: 1rem 0 2rem;
	}

	.illustration {
		text-align: right;
	}
	
	.frontpage-banner-heading {
		font-weight: 800;
		letter-spacing: -0.05em;
		font-size: 45px;
		font-size: 40px;
		max-width: 80%;
		margin-bottom: 0.5em;
		color: #38c172;
	}

	.frontpage-banner-slogan {
		font-size: 20px;
		max-width: 80%;
		font-size: 1.1rem;
	}

	.frontpage-banner-upload {
		align-self: top;
	}

	.illustration img {
		
		height: 480px;

	}
	
	.dropzone-select {
		margin-top: 3em;
		margin-top: 2em;
		background: transparent !important;
		border-radius: 5px !important;
		border-width: 2.5px !important;
		border-color: #3490dc !important;
	}

}
.btn-orange {
	background: #FA6041;
	color: #fff;
}
.btn-orange:focus,
.btn-orange:hover {
	color: #fff;
	background: #ff8c4d;
}
.btn-bordered {
	border: solid 1px #CCC;
	font-weight: 400 !important;
	color: #6c757d !important;
}

#homepage-features,
#final-upload {
	background: #FFF;
	border: solid 2px #eaf3fb;
	border-width: 3px 0;
	color: #4c4c4c;
	padding: 1.5rem;
	font-size: 16px;
	font-weight: 400;
}

#homepage-features {

	i {
		font-size: 40px;
		vertical-align: middle;
		margin-right: 10px;
	}

	.features-row {
		align-items: center;
	}

}

#homepage-reviews {
	background: #D65338;
	padding: 2rem 0;
	margin-top: 6rem;
	color: #fff;
}

.semi-medium-container {
	padding: 0 65px;
}
.frontpage-testimonials {
	margin-top: 60px
}
.frontpage-testimonial {
	text-align: center;
	color: #fff;
	padding: 1rem;
}
.testimonial-avatar {
	width: 130px;
	height: 130px;
	flex-shrink: 0;
	display: inline-block;
	background: #FFF;
	border-radius: 100%;
	position: relative;
}
.testimonial-avatar img {
	max-height: 110px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	vertical-align: bottom;
}
.frontpage-testimonial p {
	margin: 1rem 0;
}
.testimonial-author-name {
	font-weight: 600;
	font-size: 1.1rem
}

#homepage-papers {
	margin-top: 5rem;
}

.homepage-paper {
	.title {

	}
	.price {
		font-weight: 600;
		margin-top: 0.2rem;
	}
}
.homepage-paper {
	margin-bottom: 1em;
}
.homepage-paper img {
	max-width: 100%;
	border: solid 2px rgba(204, 232, 240, 0.38);
    border-radius: 5px;
    margin-bottom: 0.5rem;
}
.latest-paper {
    display: flex;
    align-items: center;
    max-width: 350px;
    margin-bottom: 1em
}
.latest-paper {
	img {	
	    width: 80px;
	    height: auto;
	    border: solid 2px rgba(204, 232, 240, 0.38);
	    margin-right: 10px;
	    align-self: center;
	}
}
#final-upload {
	margin-bottom: -10rem;
	margin-top: 4rem;
}
.upload-files-modal .modal-dialog {
	min-width: 360px;
	max-width: 500px;
	width: 80%;
}
.homepage .dropzone-previews {
	margin: 0.7rem 0;
}
.homepage .dropzone-previews .alert-light {
	background: #F7F7F7;
}
.alert-light {
	background: #F7F7F7;
}
.documentation {
	background: #FFF;
	p,
	table,
	ul {
		font-size: 1.1rem;
	}
}
.card-grey {
	background: #F1F1F1;
}
.documentation {
	h1 {
		font-weight: 600;
		margin-bottom: 0.6em;
	}
	h2.large {
		font-size: 2.5em;
		margin-top: 1.5em;
	}
	h2 {
		font-weight: 600;
		margin: 1.3em 0 0.8em 0;
	}
    
    h3 {
    	margin-top: 1.5em;
    	margin-bottom: 1em;
    }
        
    h4 {
    	margin-top: 1.5em;
    	margin-bottom: 0.8em;
    }

	.features h3 {
		font-weight: 700;
		margin-top: 1em;
		margin-bottom: 0.5em;
	}
    
	.card {
		font-size: 0.95rem
	}

	.card p:last-child {
		margin-bottom: 0;
	}
	.step {
		display: inline-block;
		text-align: center;
		color: #FFF;
		background:#D63878;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: Center; 
		margin-right: 10px;
		border-radius: 5px;
	}
}
@media screen and (min-width: 80em) {
	.documentation {
		padding-left: 1rem;
	}
}
.article-nav-container {
	flex-basis: 280px;
}
.article {
	flex-basis: calc(100% - 300px);
}
.article-nav {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 1rem
	}
	ul li {
		padding: 0.5rem 0;
	}
	a {
		color: #868686;
		font-weight: 600;
		font-size: 90%;
		text-decoration: none;
		padding: 0.2em 1em
	}
	a:hover,
	a.active {
		color: #000;
	}
}
@media (min-width: 567px) {
    .affix {
      position: fixed;
      top: 107px;
      margin-top: 0;
    }
    .article-nav-container .affix {
    	top: 127px;
    }
}

.affix-top {
  position: static;
}

.affix-bottom {
  position: relative;
}
	.section-title {
 font-weight: 700;
    font-size: 1.45rem;
    padding-bottom: 0.5rem;
    padding-bottom: 0.3rem;
	}
.single-product {
	.catalog-item {
		header {
			margin-bottom: 0;
		}
	}
	.ratings-block {
		margin-top: 0em;
		font-size: 1.3em;
		line-height: 40px;
		display: flex;
	}

	.author-rating {
		margin-left: 40px;
		font-size: 0.9rem
	}

	.rating-count {
		font-size: 0.9rem;
		margin-left: 8px;
	}

	.card {

	}
	header {
		margin-bottom: 2.5em;
		font-size: 16px;
		  a {
    	font-weight:600;
    }

    a:hover {
    	text-decoration: underline;
    }
	}
	h1 {
		font-weight: 800;
		margin-bottom: 0.5em;
		max-width: 95%
	}

	.top-links {
		margin-bottom: 1em;
	}

	.top-links a {
		color: #6c757d;
	}

	.top-links a:not(:last-of-type):after {
		content: "/";
		margin: 0 0.5rem;
	}
	.card {

	}
	.card-body,
	.card-content {
	    padding: 0rem;
	}
	.card-title {
		font-size: 1.4rem;
		font-weight: 700;
		margin-bottom: 1rem;
	}
	.paper-type {

	}

	.card p {
		margin-bottom: 0.6rem;
		font-size: 16px;
	}
	.card p:empty {
		margin: 0;
	}
	.card {
		font-size: 1rem;
		border-radius: 0.35rem;
		box-shadow: none !important;
	}
	.card-content {
		h3 {
			font-weight: 600;
			font-size: 1.4rem;
			margin-top: 1em
		}
	}
}

.toc{
	border:0;
	margin:0;
	padding:0!important;
	list-style:none;
	position:relative;
	font-size: 16px;
}

.dots{
	width:100%;
	padding:3px 0;
	overflow:hidden;
}

.dots span{
	display:inline;
	background:#FFF;
	z-index:2;
	position:relative;
}

.dots span.page-number{
	float:right
}

.dots:after{
	position:absolute;
	left:0;
	overflow:hidden;
	width:100%;
	white-space: nowrap;
	color: #c3c3c3;
	content: " ................................................................................................................................................................................................................................................................................................................................................................................................................................................";
}

.single-sidebar {
    flex: 0 0 360px;
    max-width: 360px;
}
.paper-content {
flex: 0 0 calc(100% - 450px);
    padding-right: 48px;
    max-width: none;
}
#paper-purchase-menu {
	width: 330px;
}
#paper-purchase-menu {
	.read-excerpt {
    background: rgba(35, 90, 214, 0.85);
    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    position: absolute;
    top: 33%;
    font-weight: 600;
    right: -5px;
    transition: all 300ms;
    z-index: 20;
    border-radius: 2px 0 0 2px;
    font-size: 1.05rem;
    z-index: 20;
	}
.read-excerpt:after {
    content: "";
    position: absolute;
    width: 0;
height: 0;
border-style: solid;
border-width: 5px 0 0 5px;
border-color: transparent transparent transparent #31497f;
right: 0px;
top: -5px;
}
	figure img {
   		position: relative;
   		    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
   		max-height: calc(100vh - 300px);
   		min-height: 200px;
   		border-radius: 0.5rem;
	}
	figure {
		position: relative;
		text-align: left;
		max-width: 100%;
	}
	figure a {
		z-index: 10;
		display: inline-block;
		text-transform: lowercase;
		position: relative;
		cursor: zoom-in;
    color: #3e3e3e;
    font-size: 1em;
        text-decoration: none;
	}
    figure a:hover .read-excerpt {
    	background: rgba(35, 90, 214, 1);

    }
    figure a .arrow-down {
    	position: relative;
    	top: 1px;
    }
}
.single-paper-price {
	margin: 0 0 0.5em;
	color: #000;
	font-weight: 700;
	font-size: 2em;
}
.initials {
height: 30px;
    background: #f1f1f1;
    color: #6c757d;
    width: 30px;
    border-radius: 100%;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    margin-right: 4px;
}
.review {
	margin-bottom: 1em;
	border-bottom: solid 1px #eee;
	padding-bottom: 1em;
	.author {
		font-weight: 1em;
		font-weight :600;
	}
	.date {
		margin-top: 0.3em;
		font-size: 80%;
		color: #868686;
	}
}
.review:last-of-type {
	border-bottom: 0;
}
.golden {
	color: #a77d06;
}
.initials.small {
	height: 25px;
	width: 25px;
	line-height: 26px;
	margin-right: 3px;
}
.author-rating .rating {
	margin-left: 10px;
}
#paper-images {
	padding-right: 0px;
	padding-top: 5px;
	margin: 0 auto;
	max-width: 700px;
    margin-bottom: 100px;
}
.paper-preview .modal-dialog {
	transform: none !important;
	max-width: 700px;
    margin: 0 auto;
    position: relative;
    left: 0;
    top: 75px;
}
#paper-images img {
	max-width: 100%;
	width: 100%;
	backface-visibility: hidden;
}
.paper-images-footer {
	background: #F1F1F1;
	padding: 10px;
}

.responsive-video {
	position: relative;
	margin: 0;
	max-width: 800px;
	height: 0;
	padding-top: 56.25%;
}
.responsive-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.video-container {
	max-width: 800px;
	margin: 0 auto;
}
#video-container {
	margin-top: -2.5em;
	background: #167ac6;
	padding: 20px;
}
.system-content {
	font-size: 1.03rem;
}
.system-content {
		
	h2 {
		margin-bottom: 1em;
	}

}
.md-navbar + .hero {
	margin-top: -2.5em;
	background: #fff
}
.display-4 {
	margin-bottom: 0.5em;
}
.accordion .card-header {
	background: #FFF
}
.accordion .collapse.show,
.accordion .collapsing {
	border-bottom: solid 1px #DDD;
}
#duk .btn {
	text-align: left;
}

.main-box {
	min-height: calc(100vh - 700px);
}
.single-product .annotation {
    margin: 1em 0 1em;
    font-size: 1rem;
    font-style: italic;
    border: dashed 1px #ddd;
    border-width: 1px 0;
    padding: 15px 0;
}
.quick-facts {
	display: flex;
	dl {
		border: solid 1px #eee;
		display: flex;
		flex: 1;
		flex-flow: row wrap;

		dt {
			padding: 0 1em;
			flex-basis: 33.33%;
			background: #fbfbfb;
			border-bottom: solid 1px #ebebeb;
			height: 45px;
			line-height: 45px;
			overflow: hidden;
			margin-bottom: 0;
			border-right: solid 1px #ebebeb;
		}
		dd {
			padding: 0 1em;
			flex-basis: 66.66%;
			border-bottom: solid 1px #ebebeb;
			height: 45px;
			line-height: 45px;
			overflow: hidden;
			margin-bottom: 0;
		}
	}
}
#paper-preview-modal .modal-content {
	box-shadow: none;
	background: transparent;
}
#paper-images img {
	box-shadow: 0 0 3px #ddd;
	margin-bottom: 0.5em;
}
.single-product .modal-backdrop {
	background: #000;
}
.paper-images-header {
	background: #fff;
	position: fixed;
	width: 100%;
	z-index: 100000;
	padding: 15px 0 10px;
	line-height: 1.2;
	box-shadow: 0 0 2px rgba(0,0,0,0.3);
}
.btn.text-muted {
	font-weight: 400;
}
#loading-modal {
.modal-content {
    padding: 60px;
    text-align: center;
	border: 0;
	i {
		font-size: 4em;
		color: rgba(35, 90, 214, 1);
		margin-bottom: 0.35em;
	}
}

}
@media screen and (max-width: 767px) {
    #homepage-intro .frontpage-banner-heading {
    	font-size: 30px;
    }
    .homepage h2 {
    	font-size: 1.4rem
    }
    .homepage .mt-5 {
    	margin-top: 2rem !important;
    }
    .homepage .card-title {
    	font-size: 1.2rem;
    }
    #homepage-reviews {
    	margin-top: 3rem;
    	.mb-5 {
    	    margin-bottom: 1.5rem !important;	
    	}
    }
    .frontpage-testimonials {
    	margin-top: 30px;
    }
    #homepage-papers {
    	margin-top: 50px;
    	.mb-5 {
    		margin-bottom: 2rem !important;
    	}
    }
    #final-upload {
    	margin-top: 1rem;
    }
}
.about-us {
	border-radius: 0;
	margin-top: -48px;
}
.featured-heading {
position: relative;
    display: inline-block;
    font-weight: 700 !important;
    margin-bottom: 5rem !important;
    letter-spacing: -0.03em;
    font-size: 2.7rem;
}
.featured-heading:after {
	content: "";
	position: absolute;
	width: 100%;
	background: #D63878;
	width: 100%;
	height: 6px;
	bottom: -10px;
	border-radius: 8px;
	left: 0;
}
.documentation h3 {
	font-weight: 700;
}
.documentation {
	.icon {
		width: 3.5rem;
		height: 3.5rem;
		background: rgb(19 171 26 / 11%);
		line-height: 3.5rem; 
		text-align: center;
		border-radius: 10px;
		i {
			font-size: 1.5rem;
			line-height: 3.5rem;
			color: #063308;
		}
	}
}
body:not(.homepage):not(.login-page) {
	margin-top: 127px;
}
.single-product {

}
@media screen and (max-width: 767px) {
	body:not(.homepage):not(.login-page) {
	margin-top: 103px;
    }
    .mobile-search {
    	margin-top: -40px;
    }
}
.paper-type a {
	color: #6c757d;
}
.service-testimonial {
    padding: 0.8rem 0;
    background: #FAFAFA;
    padding: 1.5em;
    margin-bottom: 0.8em;
    .comment {
    	font-size: 100%;
    }
    .name {
    	font-weight: 600;
    	color: #000;
    }
}
.service-testimonial:last-of-type {
	border-bottom: 0;
}
.cart-sidebar {
	padding-left: 60px;
}
.checkout-sidebar-block {
	padding: 30px 30px 20px;
	border-radius: 5px;
	background: #fff;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5em;
	border: 0;
}
.old-price {
	text-decoration: line-through;
}
.user-content {
	font-size: 0.9rem;

	h2 {

		font-size: 1.2rem;
		font-weight: 600;
		margin: 1rem 0 0.8rem;

	}
}
.affiliate-tos {
	max-height: 80vh;
	overflow-y: scroll;

	.table-sm th, 
	.table-sm td{
		padding: 0.3rem;
	}
}
.dataTables_wrapper {
	.pagination {
		justify-content: flex-end; 
		margin-top: 0.2em;
	}
}
.statement-links {
	
	a:not(:last-of-type):after {
		content: "|";
		color: #6c757d;
		margin-left: 0.3em;
	}

	a {
		margin-right: 0.3em;
	}

}
.small-preview {
	width: 637px !important;
	max-width: none !important;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	img {
	    width: 209px !important;
	}
}
.mobile-purchase-box {
	height: 50px;
	position: fixed;
	bottom: 0;
	background: #38c172;
	width: 100%;
	z-index: 2;
	box-shadow: 0 0 5px 1px #ddd;
}
.mobile-single-buttons {
	display: block;
	color: #fff;
	line-height: 50px;
text-align: center;
padding: 0;
border: none !important;
}
.mobile-add-to-cart.activated:focus > span
.mobile-add-to-cart.activated:active > span,
.mobile-add-to-cart.activated:hover > span,
 {
	display: none;
}
.mobile-add-to-cart .loading {
	display: none;
}
.mobile-add-to-cart.activated:focus > .loading,
.mobile-add-to-cart.activated:hover > .loading {
	display: inline-block;
}

.mobile-single-preview {
	background: #fff;
	color: #777;
}
.mobile-single-preview:focus,
.mobile-single-preview:hover,
.mobile-add-to-cart:hover,
.mobile-add-to-cart:focus {
	text-decoration: none;
}
.mobile-add-to-cart:focus,
.mobile-add-to-cart:hover {
	color: #fff;
}
.payment-promoter {
	background: #235ad6;
	color: #fff;
	margin-top: 0.4em;
	padding: 1em;
	border-radius: 5px;
	text-align: center;
	.secure-payment {

        .large {
           display: block;
           font-size: 1em;
           font-weight: 700;
           text-transform: uppercase;
        }
        .small {
        	font-size: 0.8em;
        	text-transform: uppercase;
        }

	}
}
.mobile-count {
	background: #D43776;
	width: 15px;
	height: 15px;
	line-height: 15px;
	font-size: 10px;
	font-weight: 700;
	border-radius: 100%;
	text-align: center;
	color: #fff;
	position: absolute;
    left: 25px;
    top: 8px;
	transform: scaleX(-1);
}
.cart-table {
	a {
		color: #000;
	}
	box-shadow: 0 1px 3px rgba(0,0,0,0.15);
	border-radius: 0.35rem;
	th {
		background: #fafafa;
	}
	.paper-image {
		padding: 0 0 0 15px !important;
	}

}
.payment-promote-box {
    margin-top: 3em;
    border-bottom: solid 1px #e5e5e5;
    padding-bottom: 2em;
    h5 {
    	margin-bottom: 0;
    }
    .checkpoint {
    	display: flex;
    }
    .checkpoint .icon {
    	width: 40px;
    	content: "";
    	font-family: "Font Awesome 5 Free";
    	display: inline-block;
    	float: left;
    	height: 40px;
    	flex-shrink: 0;
    	margin-right: 15px;
    	color: #fff;
    	background: #c74cb1;
    	border-radius: 5px;
    	line-height: 40px;
    	text-align: center;
    	font-size: 25px;
    }
}
.discount-promo {
	display: flex;
	align-items: center;
	line-height: 1.1;
	i {
		margin-right: 20px !important;
		display: inline-block;
	}
}
.user-page {
	body {
		margin-top: 0 !important;
	}
	.navbar {
		position: relative;
		margin-bottom: 0;
	}
	.user-nav {
		padding: 0.4rem 0;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.user-nav a {
		font-weight: 600;
		margin-right: 25px; 
		color: #6c757d;
		font-size: 95%;	
	}
	.user-nav a.active,
	.user-nav a:focus {
		color: #000;
		background-color: transparent;
	}
	.user-nav .navbar-nav {
		flex-flow: row;
	}
}
.paper-extension-icon {
	width: 0.6em !important;
}
.cart tfoot td {
	border: 0;
}
.price-column { 
	padding: 0 10px !important;
}
.credits-column {
	padding: 0 5px !important;
}
.cart th {
	vertical-align: middle !important; 
}
span.label {
	background: green;
	color: #fff;
	display: inline-block;
	padding: 0px 5px;
	margin-left: 5px;
	border-radius: 5px;
}
.credit-price {
    font-size: 28px;
    color: #325ad6;
    line-height: 1;
    background: #fff;
    margin-bottom: 0.2em;
    width: 100%;
    display: inline-block;
    border-radius: 4px;
    padding: 10px 0px 9px;
    border: solid 1px #eeeeee;
    margin-bottom: 0.5em;
	.amount {
		letter-spacing: 1px;
		margin-left: 3px;
		font-weight: 600;
		border: 0;
		width: 70px;
		font-size: 28px !important;
		color: #325ad6;
	}
	.currency {
		font-size: 50%;
		vertical-align: super;
	}
	.per-credit {
		font-size: 50%;
		vertical-align: bottom;
		line-height: 1; 
		position: relative;
		top: -4px;
		left: -9px;
	}
}
.seperator {
	margin-bottom: 32px;
}
.my-order-sidebar {
	border-left: solid 1px #f3f3f4;
	padding-left:40px;
	padding-top: 30px;
	padding-bottom: 100px;
}
.checkout-page {
	margin-top: -2.75rem;
	h5 {
		font-weight:600;
	}

}
.checkout-content {
	padding-top: 1.75rem;
	padding-right: 2.75rem;
	padding-bottom: 130px;
}
.order-items {
	max-height: 160px;
	overflow-y: auto;
}
.order-item {
	display: flex;
	border-radius: 8px;
	background: #f3f3f4;
	padding: 15px;
	line-height: 1.2;
	align-items: center;
	margin-bottom: 10px;
	.rating {
		position: relative;
		top: -2px;
		margin-right: 5px;
	}
	.title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
		a {  
            color: #000;
		}
	}
	figure {
		width: 30px;
		margin-right: 10px;
		margin-bottom: 0;
		img {
			max-width: 100%;
			border: solid 1px #f3f3f4;
		}
		flex-shrink: 0;
	}
	main {
		width: calc(100% - 100px);
	}
	.price {
		width: 80px;
		text-align: right;
	}
}
	.paper-ad a {
		color: #ea4c89;
	}
.totals {
	font-size: 1.5rem;
	font-weight: 700;
}
.order-items.scroll-activated {
	padding-right: 15px;
}
.order-item.mobile {
	background: #fff;
	padding: 0;
	main {
		width: 100%;
	}
	.price {
		float: none;
		text-align: left;
		div {
			display: inline;
		}
	}
}
.order-item.mobile:last-of-type {
	margin-bottom: 0;
}
.checkout-content {

	.card {
		border-radius: 8px;
		padding: 5px;
        h5 {
        	font-weight: 600;
        }
        p {
        	line-height: 1.2;
        }
	}
}
.form-control {
	border-radius: 8px;
	height: calc(1.6em + 0.9rem + 2px);
    padding: 0.45rem 0.75rem;
}
.btn:not(.btn-sm) {
	padding: 0.45rem 0.75rem
}
.btn-secondary {
    color: #0d0c22;
    background-color: #f3f3f4;
    border-color: #f3f3f4;
}
.btn-secondary:hover,
.btn-secondary:focus {
	background-color: #e7e7e9;
	 border-color: #f3f3f4;
	 color: #0d0c22
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
		background-color: #e7e7e9;
	 border-color: #f3f3f4;
	 color: #0d0c22;
	     box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.2) !important;

}
.btn-secondary:focus, .btn-secondary.focus {
	box-shadow: 0 0 0.2rem 0em rgba(130, 138, 145, 0.05) !important;
}
.card.inactive {
	color: #888;
	background: #FAFAFA;
	.info-group {
		display: none;
	}
}

.result-group {
	display: none;
	margin-bottom: -5px;
}
.result-group > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.card.has-data {
    .info-group {
    	display: none;
    }
    .result-group {
    	display: block;
    	margin-bottom: -5px;
    }
}
#payment-box .result-group img {
	max-height: 45px;
	margin-top: 5px;
}
     .card .edit-button {
     	position: absolute;
     	top: 20px;
     	right: 20px;
     }
.checkout-content .payment-methods {
     margin: 0 -3px;
     > * {
     	padding: 3px;
     }

}
.checkout-content .card-body:after {
	transition: all 300ms;
}
.blackout:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #000;
    opacity: 0.05;
}
.static-header {
	position: absolute;
}
.payment-info {
	background: #2f53ad;
	color: #FFF;
	margin-bottom: -10em;
}
.checkout-page.mobile {
	margin-top: -1.5em;
}
.payment-info {
	hr {
		background: #fff;
		color: #fff;
		opacity: 0.2;
	}
	.service-testimonial {
		background: #fff;
		border-radius: 8px;
		margin: 10px;
	    .rating {
	    	font-size: 17px;
	    }
		.comment {
			color: #222;
		}
	}
	padding: 20px;
	.flex {
		display: flex;
		> * {
	    flex: 1;
	    padding-bottom: 5px;
		}
	}
	p {
		margin: 0;
	}
	h5 {
		margin-bottom: 0;
	}
	.quick-download {
	}
	.helpdesk {
	}
	.refund {

	}
	i {
		margin-right: 5px;
	}

}
@media screen and (max-width: 1199px) {
	.checkout-page-desktop {
	}
	.checkout-page-desktop {
		max-width: none;
		min-width: 1000px;
		padding: 0 20px;
	}

}
.payment-info.mobile {
	margin-bottom: 0;
    margin-top: 3em;
    border-radius: 8px;
    background: #f3f3f4;
    color: #636363;
	margin-bottom: 0;
	margin-top: 3em;
	border-radius: 8px;
	.service-testimonial {
		padding: 15px;
	}
}


#credits-ad {
  
background: #fff;
    color: #23272b;
    margin-top: 100px;
    font-weight: 400;
    margin-bottom: -145px;
    text-align: center;
    padding: 50px 40px 40px;
    font-size: 1.3rem;
    box-shadow: 0 0 2px rgb(0 0 0 / 20%);

    .icon {
    	font-size: 2.5rem;
    	color: #235ad6;
    	margin-bottom: 1rem;
    }
}

#credits-ad a {
    display: inline-block;
    margin: 0 auto;
    margin-top: 1.2rem;
    border: 0;
    font-size: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 8px;
}

@media screen and (max-width: 991px) {
	#credits-ad {
		margin-bottom: -100px;
	}
}

@import 'media';
@import 'login';