.btn-upload {
  padding: 1rem;
  font--size: 1.1rem;
}


.paper-extension-icon {
  font-size: 30px;
  margin-left: 1rem;
}
.paper-count {
  background: rgb(108 117 125 / 44%);
  border-radius: 100%;
  color: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin-left: 0.5em;
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
}
.nav-tabs {
  margin: 1.5em 0;
  border-bottom: solid 1px #ccc;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 2px solid #38c172 !important;
    padding: 1.3em 0;
    color: #38c172;
}
.nav-link.active .paper-count {
  background: #38c172;
}
.nav-tabs .nav-link:not(.active):hover {
  border-color: transparent !important;
  color: #222;
}
.nav-tabs .nav-link {
    border-bottom: 2px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    padding: 1.3em 0;
    margin-right: 1.5em;
    color: #6c757d;
    font-size: 0.93em;
    letter-spacing: 0.02em;
    transition: all 300ms;
}
.btn-new-paper {
  padding: 0.5em;
  height: 39px;
  margin-top: 11px;
  line-height: 21px;
  font-weight: 600;
  color: #fff !important;
}
.btn-new-paper ion-icon {
  position: relative;
  top: 3px;
}

.dataTables_info {
    padding: 0.5rem 1.25rem;
}
.action-icons,
.action-icons .btn-link {
  font-size: 25px;
  padding: 0;
}
.btn-sm ion-icon {
  font-size: 110%;
  position: relative;
  top: 3px;
}
.action-icons a:hover {
  text-decoration: none;
}
.paper-data-form .row {
  padding-top: 1rem;
  transition: background 300ms;
  border: dashed 1px transparent;
  border-radius: 10px;
}
.paper-data-form .row:hover {
  border-color: #e0e0e0;
}
.paper-data-form .row:hover label {
  font-weight: 600;
}
.paper-data-form .row label {
  font-size:  1.15em;
}
.page-introduction {
  margin-bottom: 2em
}
.input-explanator {
  margin-top: 0.7em;
  font-size: 0.9em;
}
.margin-top { 
  margin-top: 1em;
}
.page-introduction p { 
  max-width: 80%
}
.login-card {
  max-width: 400px;
}
.card {
  border: 0;
  box-shadow: 0 0 3px rgba(0,0,0,0.15);
  border-radius: 0px;
}
.btn {
  font-weight: 600;
}
div#auth-logo {
    max-width: 300px;
    text-align: center;
    margin-bottom: 1.5rem;
}
#auth-logo img {
  max-width: 80px;
}
.btn-transparent {
  border: solid 1px rgba(255,255,255,0.5);
}
.number {
  background: #9561e2;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 30px;
    text-align: center;
    color: #FFF;
    margin-left: 5px;
    font-weight: 600;
}
label.is-invalid {
  margin-top: 1.2em;
  margin-bottom: 0;
  font-size: 0.8em !important;
  color: #e3342f;
}
#consent-error {
  display: none !important;
}
.text-danger a {
  color: #e3342f !important;
}
.user-summary-box {
    background: #325ad6;
    color: #fff;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
}
.user-summary-box {
  a {
    color: #fff;
  }
  h6 {
    font-size: 90%;
   margin-bottom: 0;
  }
  .value {
    font-size: 1.9em;
    font-weight: 600;
    color: #FFF;
    margin-top: 0.25em;
    margin-bottom: 0.05em;
    line-height :1;
  }
  .text-muted {
    color: rgba(255,255,255,0.9) !important;
  }
  .value + * {
    font-size: 90%;
  }
  h6 i {
    font-size: 80%;
    color: rgba(255,255,255,0.8);
  }
  .sale-profit-box {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 340px) {
  .user-summary-box {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .nav-tabs {
    flex-flow: row wrap;
  }
}
.avatar {
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #EEE;
  border-radius: 100%;
  text-align: center;
  line-height: 70px;
  margin-right: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
}
#paper_rules_checkbox-error {
  display: none;
}