.centered-heading {
	text-align: center;
	font-size: 1.7rem;
	font-weight: bold;
	margin: 2.3em 0 1em;
}

.page-title {
	font-size: 1.7rem;
	font-weight: 800;
	margin-bottom: 1em;}

.page-title + p {
	margin-top: -1.5em;
}
.top-spacing {
  margin-top: 2em
}

.centered-heading + p {
  margin: 0 auto 3em auto;
  max-width: 700px;
}

.small-container {
  max-width: 600px !important;
  margin: 0 auto;
}

.medium-container {
  padding: 0 5%;
}
table tr:first-child th:first-child {
    border-bottom-left-radius: 10px;
}

table tr:first-child th:last-child {
    border-bottom-right-radius: 10px;
}
.table thead th {
  color: #6c757d;
  border-bottom-width: 1px;
  font-weight: 400;
  padding: 0.5rem 1rem;
}
.table thead th:focus {
	outline: none
}
th.sorting_asc:after {
	content: "ï";
    font-family: "Font Awesome 5 Free";
    color: #000;
    margin-left: 5px;
    font-weight: 900;
    position: relative;
    top: 3px;
    color: #555;
}
th.sorting_asc,
th.sorting_desc {
	color: #555 !important;
}
th.sorting_desc:after {
    content: "ï";
    font-family: "Font Awesome 5 Free";
    color: #000;
    margin-left: 5px;
    font-weight: 900;
    position: relative;
    top: -3px;
    color: #555;
}
th.sorting:after {
    content: "ï";
    font-family: "Font Awesome 5 Free";
    color: #000;
    margin-left: 5px;
    font-weight: 900;
    color: #CCC;
}
th.sorting,
th.sorting_asc,
th.sorting_desc {
	cursor: pointer;
}
.table:not(.table-sm) tbody td {
  padding: 1.2rem 1rem;
  line-height: 1.2;
  vertical-align: middle;
}

.min-width-900 {
  min-width: 900px;
}


.page-heading {
  font-weight: 700;
}

.flex-one-row {
  display: flex;
}

.aside-title {
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.credits-table.bordered-table {
	border-color: #000;
}

.btn-outline-orange {
	color: #fff !important;
	border: solid 1px #fff;
}
.btn-outline-orange:hover {
	color: #fff !important;
}