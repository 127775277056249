.md-navbar {
background: #fff;
margin-bottom: 2.5em;
position: fixed;
width: 100%;
padding: 0;
top: 0;
padding: 0.86rem 0rem;
align-items: center;
z-index: 1000;
box-shadow: inset 0px -1px 0px #f3f4f4;

  form {
     width: 100%;
     margin-right: 50px;
  }
  .container {
  	width: 100%;
  }
}
.md-navbar > .container {

}
.md-navbar.sticky {
  border-bottom: 0;
  padding-bottom: 1rem;
}
.navbar-brand {
  margin-right: 3rem;
}

.navbar-brand img {
  height: 37px;
  position: relative;
  top: 3px;
}

.single-letter-avatar {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #dd5639;
  line-height: 40px;
  border-radius: 8px;
  margin-left: 0.7rem;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .md-navbar {
    padding: 0.6rem 0;
  }
}
@media screen and (min-width: 992px) {
  .header-search button[type=submit] {
    display: none;
  }
  .header-search input[type=text] {
    padding-left: 50px !important;
  }
  .header-search:before {
    content: "ï";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: 24px;
    top: 13px;
    color: #6c757d;
  }
  .header-buttons {
	white-space: nowrap;
	display: flex;
	align-items: center;
}
}