.login-page {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;

	.logo {

		img {
			max-width: 50px;
		}
	}

	.login-heading {
		justify-content: space-between;
		margin-bottom: 0.5em;
	}

	.signin-block {
box-shadow: 0 0 3px #ddd;
		padding: 40px;
		background: #fff;

	}

	h2 {
		font-weight: 600;
		font-size: 1.6rem;
		margin-bottom: 0em;

	}

	.small-container {
		width: 100%;
		min-width: 350px;
		flex-shrink: 0;
	}

	.form-group {
		margin-bottom: 0.6em;
	}

	.btn-social {
		border: solid 1px #ddd;
		font-weight: 400;
		color: #555;
	}
	.btn-social i {
		font-size: 80%;
		margin-right: 0.4em
	}

}
@media screen and (max-width: 399px) {
	.login-page {
		background: #fff;
	}
}
@media screen and (min-width: 400px) {
	.login-page {

		.small-container {
			width: 350px;
		}
	}
}
.btn-fb-login i {
	color: #3B5998;
}
.btn-google-login i {
	color: #DB4437;
}
@media screen and (max-width: 400px) {
  .login-page .signin-block {
    border: 0;
    padding: 30px !important; 
    box-shadow: none;
  }
}
@media screen and (max-width: 350px) {
	.small-container {
		min-width: 0 !important;
		width: 100% !important;
	}
}