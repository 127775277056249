.page-footer {
  padding: 4rem 2rem 0;
  background: #0D2657;
  margin-top: 10em;
  color: rgba(255,255,255,0.9);
      background: #FFF;
    margin-top: 10em;
    color: #222;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
     h5 {
       font-size: 1.2em;
     }
       padding-bottom: 1em;
}
.copyright {
    margin: 1em 0;
    font-size: 90%;
    opacity: 0.9
}
.page-footer a {
  color: #222;
  color: #6c757d;
  line-height: 2;
  font-size: 1em;

}
hr {
  margin-top: 1em;
}
#footer-logo a {
  display: inline-block;
  width: 50px;
  overflow: hidden;
  vertical-align: middle;
}
#footer-logo img {
  float: left;
  width: 240px;
  vertical-align: middle;
}
.copyright {
  margin-top: 10px;
  font-size: 0.9em;
  line-height: 1.5;
  display: block;
}