@media screen and (min-width: 768px) {
  .statement-operation-type {
  max-width: 320px;
}
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  #sortBy {
    width: 170px;
    position: absolute;
    right: 15px;
    bottom: 7px;
  }
}

@media (min-width: 1366px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (min-width: 1500px) {
  .container {
    max-width: 1300px;
  }
}
@media screen and (max-width: 991px) {
  #login-form input {
    font-size: 16px;
  }
  .display-4 {
    font-size: 2.5rem;
  }
  .container {
    padding: 0 1rem !important;
  }
  .frontpage-offer {
    padding: 3rem 1rem 0.5rem !important;
  }
  #homepage-intro {
    background: rgb(56,193,114);
    background: linear-gradient(15deg, rgba(56,193,114,1) 0%, rgba(44,210,114,1) 100%);
    background: #235ad6;
  }
  #homepage-intro {
    nav {
    padding: 1rem 0 0.5rem;
    background: #fff;
    }
    .frontpage-banner-heading {
      color: #fff
    }
    .frontpage-banner-slogan {
      color: rgba(255,255,255,0.9);
    }
  } 
  #homepage-features {
    text-align: center;
    padding: 3rem 0 2rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: #14203c;
  }
  #homepage-features {
    
    .features-row > * {
      margin-bottom: 1em;
    }
    
    i {
      font-size: 1.8rem;
      color: #235ad6;
    }
  }
  #how-it-works {
    .card {
      margin-bottom: 1em;
    }
  }


  .homepage-paper {
    padding-bottom: 1em;
  }
  .homepage-paper:not(.last) {
    border-bottom: dashed 1px rgba(20, 32, 60, 0.23);
  }
  .homepage-paper img {
    display: none;
  }
  .navbar > .container, .navbar > .container-fluid {
    display: block;
    position: relative;
  }
  .navbar-brand {
    text-align: center;
    width: auto;
    margin: 0 auto;
    display: inline-block;
  }
  .static-top {
  	text-align: center;
  }
  .navbar-toggler {
    position: absolute;
    left: 10px;
    top: 7px;
  }
  #mobile-cart-toggler {
    position: absolute;
    right: 10px;
    top: -2px;
    padding: 10px;
    font-size: 1.3rem;
    color: rgb(200, 202, 202);
    transform: scaleX(-1);
  }
  .navbar-collapse {
    position: fixed;
    background: #FFF;
    top: 0;
    left: 0;
    width: auto;
    overflow-x: hidden;
    padding: 10% 3% 100px 15px;
    padding-top: 100px;
    height: 100vh;
    max-width: 270px;
    overflow-y: auto;
    z-index: 10000000;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.14901960784313725);
  }
  .navbar-collapse a:not(.btn) {
    display: block;
    font-size: 1rem;
    margin: 0 !important;
    color: #6c757d !important;
    text-align: left;
    border: 0;
    font-weight: 400 !important;
    padding: 0.4em 0 !important;
    color: #6c757d !important;
    white-space: nowrap;
  }
  .login-button {
  	margin-left: 0 !important;

  }
  .navbar-collapse a.active {
  	color: #000 !important;
  }
  .navbar-mobile-inner {
    min-width: 300px;
  }
  #close-mobile-menu {
    position: absolute;
    top: 30px;
    right: 30px;
    background: rgba(26, 45, 89, 0.13);
    font-size: 1.2em;
    white-space: nowrap;
    font-weight: 600;
    text-align: center;
    text-transform: lowercase;
    color: rgb(35, 89, 212);
    border: 0;
    background: transparent;
  }
  #close-mobile-menu i {
    margin-right: 0.3em;
  }
}
@media screen and (min-width: 576px) and (max-width: 991px) {
    .frontpage-offer {
      padding: 3rem 1rem 1.5rem !important;
    }
}
@media screen and (min-width: 992px) {
  .navbar-mobile-inner {
    width: 100%;
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  #homepage-intro .frontpage-banner-heading {
    font-size: 45px;
    max-width: none;
    margin-top: 1.5rem;
  }
  #homepage-features {
    color: #14203c;
  }
  .frontpage-offer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 1366px) {
   .frontpage-offer {
    padding-left: 65px;
    padding-right: 65px;
  }
}
@media screen and (max-width: 767px) {
	#empty_shopping_cart {
		padding: 3em 0;
	}
	.modal-dialog {
		min-width: 80%;
	}
	.btn-checkout {
		font-size: 1.2em;
	}
	.cart-totals {
		padding: 0 !important;
		margin-bottom: 3em;
		h3 {
			font-size: 1.3em;
			b {
				font-size: 1.3em;
			}
		}
        .checkout-button {
        	margin-top: 1.5em !important;
        }
	}

}
@media screen and (max-width: 480px) {
	.modal-dialog {
		min-width: 90%;
	}
	.page-title {
		font-size: 1.6em;
		margin-top: -0.5em;
	}
    .page-title + p {
    	margin-top: -1em;
    }
	.cart {
		line-height: 1.3;
	}
	.cart .paper-image {
		width: 0;
		padding: 0 !important;
		min-width: 0;
		max-width: 0;
		padding: 0;
		border: 0;
	}
	.cart .paper-image img {
		display: none;
	}
}
@media screen and (max-width: 480px) {
	.display-4 {
		font-size: 1.8rem;
	}
	h2 {
	  font-size: 1.6rem;
	}
}
@media screen and (max-width: 991px) {

    .paper-content {
    	width: 100%;
    	display: block;
    	flex: none;
    	padding-right: 15px;;
    }

    .single-product h1 {
    	max-width: none;
    }

    .single-product {
    	margin-top: 0;
    }

    .paper-content {
    	margin-top: 25px;
    }
    .paper-images-header {
    	background: transparent;
    	text-align: left;
    	background: #e1e1e1;
    	color: #6c757d;
    	padding-top: 8px;
    	top: 0;
    	font-size: 0.9rem;
    }
    .paper-preview .modal-dialog {
    	top: 90px;
    }
}
@media screen and (max-width: 720px) {

}
@media screen and (max-width: 767px) {
		#paper-images {
	max-width: 100%;
	padding: 0 20px;
	}
	.single-product h1 {
		font-size: 1.6rem;
	}
	.single-product .paper-type {
		font-size: 1rem;
	}
	.single-product .top-links {
		margin-bottom: 1em;
	}
    .paper-preview .prezentacija {
    	width: 150px;
    }
    		.section-title {
			font-size: 1.25em;
			font-weight: 700;
			margin-bottom: 0.2em;
		}
	.single-product {
		.ratings-block {
            display: block;
            margin-top: 0rem;
		}
		.author-rating {
			margin-left: 0;
			font-size: 0.9rem;
		}
		.rating-count {

		}
		header {
			margin-bottom: 1.2em;
		}

		ul, ol, 
		p {
			font-size: 0.9rem !important;
		}
		.quick-facts {
			font-size: 0.9rem;
			display: block;
		}
		.quick-facts dl {
			margin-bottom: 0px;
		}
		.quick-facts dl:nth-child(1) {
			border-bottom: 0;
		}
		.quick-facts dl:nth-child(2) {
			border-top: 1px;
		}
		.reviews .card {
			font-size: 0.9rem;
		}
	}
	.small-preview {
		width: 249px !important;
		display: block;
		flex-flow: wrap;
		img {
			width: 100% !important;
			max-width: 249px !important;
		}
	}
  .prezentacija .small-preview {
    width: 346px !important;
    display: flex;
    flex-flow: row wrap;
    img {
      width: 49.5% !important;
      max-width: 150px !important;
    }
    justify-content: space-between;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.navbar-brand {
		max-width: 40px;
		overflow: hidden;
	}
	.paper-content {
		flex: 0 0 calc(100% - 300px);
		padding-right: 60px;
		max-width: none;
    }
    .single-sidebar {
		flex: 0 0 260px;
		max-width: 260px;
	}
	#paper-purchase-menu {
		width: 260px;
	}
	.single-product h1 {
		max-width: 95%;
	}
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
		.paper-content {
		flex: 0 0 calc(100% - 360px);
		padding-right: 60px;
		max-width: none;
    }
    .single-sidebar {
		flex: 0 0 280px;
		max-width: 280px;
	}
	#paper-purchase-menu {
		width: 280px;
	}
	.single-product h1 {
		max-width: 85%;
	}
}
@media screen and (min-width: 768px) {
	.prezentacija #paper-images {
		width: 457px !important;
	}
	.prezentacija #paper-images img {
		width: 150px;
	}
}
@media screen and (max-width: 991px) {
.page-footer {
    background: #0D2657;
    color: rgba(255, 255, 255, 0.9);
    background: #FFF;
    padding: 0;
    margin-top: 6em;
    color: #222;
    box-shadow: none;
    text-align: left;
    hr {
    	margin-top: 0.5em;
    	margin-bottom: 1.5em;
    }
    .container {
    	text-align: left !important;
    }
    .top {
    	border: solid 1px #ddd;
    	border-width: 1px 0 0;
    	padding: 30px 0 10px;
    }
    h5 {
    	text-transform: unset !important;
    	color: #555;
    	margin: 0 !important;
        margin-bottom: 1.2em !important;
        position: relative;
    }
    h5:after {
    	content: "\f107";
    	font-family: 'Font Awesome 5 Free';
    	position: absolute;
    	right: 0;
    	transition: transform 300ms;
    }
    h5.active:after {
        transform: rotate(180deg);
    }
    ul {
    	display: none;
    	margin-bottom: 30px;
    	font-size: 1.05em;
    	line-height: 2.5;
    }
}

}
@media screen and (max-width: 991px) {
	.magic-table {
		.table thead {
			display: none;
		}
		.table tr {
			display: block;
		}
	}
}
.table-responsive .datatable {
    min-width: 600px;
    overflow-x: auto;
}
@media screen and (max-width: 480px) {
	.statement-table .table {
		thead {
			display: none;
		}
		tbody {
			display: block;
		}
		tr, td {
			display: block;
		}
		td {
			border: none;
			padding: 0 !important;
			line-height: 1.3 !important;
		}
		td:first-of-type {
			color: #6c757d
		}
		td:last-of-type {
			display: none;
		}
		td:nth-child(2) {
			margin-top: 0.2em;
		}
		td:nth-child(3) {
			font-weight: 600;
			font-size: 1.1em;
			margin-top: 0.5em;
		}
		tr {
			border: solid 1px #EEE;
			padding: 10px;
			margin-bottom: 5px;
		}
	}
}
@media screen and (max-width: 767px) {
	input, select {
		font-size: 16px !important;
	}
}
@media screen and (min-width: 768px) {
	.user-summary-box .sale-profit-box {
		margin-top: 0;
	}
	.user-summary-box {
		margin-top: 20px;
	}
}
@media screen and (min-width: 1024px) {
	.dashboard-functions {
		padding: 0 50px;
	}
	.user-summary-box {
		margin-top: 30px;
		padding: 25px;
	}
}
@media screen and (max-width: 767px) {
	.papers-table .table {
		thead {
			display: none;
		}
		tr {
			border-bottom: solid 1px #ddd;
			td:first-of-type {
				display: none;
			}
			td {
				display: block;
				border: 0;
				padding: 0;
			}
			td:nth-child(2) {
				font-weight: 600;
				padding-top: 10px;
				font-size: 1.1em;
			}
			td:nth-child(3),
			td:nth-child(4) {
				display: inline-block;
			}
			td:nth-child(3):before {
				content: "Pardavimai: ";
				color: #6c757d;
			}
			td:nth-child(3):after {
				margin-left: 0.5em;
				content: "|";
				color: #6c757d;
			}
			td:nth-child(4) {
				margin-left: 0.5em;
			}
			td:nth-child(4):before {
				color: #6c757d;
				content: "UÅ¾darbis: ";
			}
			td:nth-child(5) {
				padding-top: 0.4em;
				font-size: 90%;
				padding-bottom: 0.5em;
			}
		}
	}
	.papers-small-table .table {
		thead {
			display: none;
		}
		tr {
			border-bottom: solid 1px #ddd;
			td:first-of-type {
				display: none;
			}
			td {
			    display: block;
			    border: 0;
			    padding: 0;
			}
			td:nth-child(2) {
				padding-top: 0.6em;
				font-size: 1.1em;
				font-weight: 600;
			}
			td:nth-child(3) {
				margin-top: 0.2em;
				font-size: 90%;
				padding-bottom: 0.4em;
			}
			td:nth-child(4) {
				text-align: left !important;
				border-bottom: solid 1px #ddd;
				display: none;
			}
		}
	}
}
@media screen and (max-width: 991px) {
	.article {
		flex-basis: 100%;
		width: 100%;
		max-width: none;
	}
	.documentation p,
	.documentation table,
	.documentation ul {
		font-size: 1.08em;
	}
	.featured-heading {
		font-size: 2rem !important;
	}
	.documentation h2.large {
		font-size: 2em;
	}
}